<template>
  <page-layout>
    <template>
      <div class="analysis-page">
        <div class="left-overlay d-none d-md-block">
          <analysis-sidebar />
        </div>
        <div class="main-content">
          <div class="d-flex report-form-container align-center justify-center">
            <div class="report-form">
              <div class="headline t-24 font-weight-bold text-center">{{ messages.analysis_heading }}</div>
              <div class="description text-center t-14 mt-2">{{ messages.analysis_sub_heading }}</div>
              <div class="form text-center" :class="{ 'has-csv': csvFileName }">
                <ct-form v-model="valid">
                  <div class="mt-8">
                    <ct-button
                      v-show="!csvFileName"
                      color="primary"
                      class="upload-button"
                      @click="onUploadClick">
                      Upload CSV
                    </ct-button>
                    <ct-file-input
                      ref="uploader"
                      class="file-input"
                      :rules="[rules.required]"
                      chips
                      show-size
                      label="Upload CSV"
                      v-model="csvFile"
                      accept="text/csv">
                    </ct-file-input>
                    <div class="" v-show="!!csvFileName">
                      <div class="t-14 d-flex align-center justify-space-between upload-detail-card px-6">
                        <div class="file-name">
                          <ct-icon class="mr-1 csv-icon">cticon-csv-report</ct-icon>
                          {{ csvFileName }}</div>
                        <div class="replace ml-6" @click="onUploadClick">Replace</div>
                      </div>
                    </div>
                  </div>
                  <div class="sample-csv mt-4" :class="{ 'text-center': !csvFileName, 'text-left': csvFileName }">
                    <a :href="sampleCSV" download="CleverTapEmotionsSample" class="download-link" @click="trackCSVDownload">
                      <ct-icon class="" style="color:#126BFF;" size="16">cticon-download</ct-icon>
                      <span class="ml-2 download-csv font-weight-medium t-14" style="color:#126BFF;">Download Sample CSV</span>
                    </a>
                  </div>
                  <div class="input-fields">
                    <div class="text-left mt-10">
                      <ct-textfield
                        label="Full Name"
                        :rules="[rules.fullNameRequired]"
                        v-model="firstName"
                        placeholder="Enter Full Name" />
                    </div>
                    <div class="text-left">
                      <ct-textfield
                        label="Work email"
                        :rules="rules.emailRules"
                        v-model="email"
                        placeholder="Enter Work Email" />
                    </div>
                    <div>
                      <ct-button
                        color="primary"
                        class="submit-button mt-4" :disabled="!valid" :loading="analysisLoading" @click="uploadData"
                      >
                        Get Report
                      </ct-button>
                    </div>
                  </div>
                </ct-form>
              </div>
            </div>
          </div>
          <div class="footer d-flex align-center justify-center font-weight-bold">
            <div>
              <ct-icon size="24" class="mr-5" color="#434761">icon-emotions</ct-icon>
              <span class="footer-text">Do a quick analysis of emotions in my copy!
                <router-link class="nav-link" :to="{path: 'emotions'}" @click.native="trackTextCTA"> Take me to the text editor.</router-link>
              </span>
            </div>
          </div>
        </div>
      </div>
    </template>
  </page-layout>
</template>

<script>
  import PageLayout from './PageLayout'
  import { EventBus, Events } from '../EventBus'
  import CtButton from 'clevertap-dashboard-components/components/Button'
  import CtForm from 'clevertap-dashboard-components/components/Form'
  import CtCard from 'clevertap-dashboard-components/components/Card'
  import CtIcon from 'clevertap-dashboard-components/components/Icon'
  import CtTextfield from 'clevertap-dashboard-components/components/Textfield'
  import CtFileInput from 'clevertap-dashboard-components/components/FileInput'
  import CtSelect from 'clevertap-dashboard-components/components/Select'
  import { CtRow, CtCol } from 'clevertap-dashboard-components/components/Grid/GridSystem'
  import { mapState, mapActions } from 'vuex'
  import AnalysisSidebar from '@/components/AnalysisSidebar'

  export default {
    name: 'Analysis',
    components: {
      PageLayout,
      CtForm,
      CtCard,
      CtRow,
      CtCol,
      CtIcon,
      CtButton,
      CtTextfield,
      CtFileInput,
      CtSelect,
      AnalysisSidebar
    },
    data () {
      return {
        startTimeStamp: null,
        csvFile: null,
        sampleCSV: '/CleverTapEmotionsSample.csv',
        valid: false,
        email: '',
        firstName: '',
        lastName: '',
        company: '',
        tool: 'CleverTap',
        dau: '',
        tools: [
          'Airship',
          'Braze',
          'CleverTap',
          'Leanplum',
          'Localytics',
          'MoEngage',
          'Others'
        ],
        rules: {
          required: value => !!value || 'Required.',
          fullNameRequired: value => !!value || 'Enter your full name.',
          lastNameRequired: value => !!value || 'Enter a last name.',
          companyNameRequired: value => !!value || 'Enter a company name.',
          dauRequired: value => !!value || 'Enter a DAU count.',
          emailRules: [
            (v) => !!v || 'E-mail is required',
            (v) => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'Incorrect email format.'
          ]
        },
        result: {}
      }
    },
    mounted () {
      if (this.altId) {
        this.fetchContact(this.altId)
        this.trackRetry()
      }
      this.startTimeStamp = new Date()
    },
    methods: {
      ...mapActions(['createAnalysis', 'fetchContact', 'recordEvent', 'recordUserProfile']),
      async uploadData () {
        if (!this.email || !this.csvFile) {
          return
        }

        const data = {
          filename: this.csvFile.name
        }
        if (this.altId) {
          data.alt_id = this.altId
          data.email = this.contact ? this.contact.email : null
        } else {
          data.email = this.email
          data.first_name = this.firstName
          data.last_name = this.lastName
          data.company = this.company
          data.tool = this.tool
          data.daily_active_users = this.dau
        }
        this.$recaptcha('analysis').then(async token => {
          data.token = token
          this.result = await this.createAnalysis({ file: this.csvFile, data })
          const submitTimeStamp = new Date()
          this.recordUserProfile({
            'Site': {
              'Name': `${this.firstName} ${this.lastName}`,
              'Email': this.email
            }
          })
          this.recordEvent({
            'name': 'Submitted CSV',
            'properties': {
              'Email': this.email + '',
              'Filename': this.csvFile.name + '',
              'Company name': this.company + '',
              'timespent': (submitTimeStamp.getTime() - this.startTimeStamp.getTime()) / 1000
            }
          })
          this.notifyResult()
        })
      },
      notifyResult () {
        EventBus.$emit(Events.SHOW_SNACKBAR, { color: this.resultColor, text: this.resultText })
      },
      onUploadClick () {
        this.$refs.uploader.$el.querySelector('input[type="file"]').click()
      },
      trackCSVDownload () {
        this.recordEvent({
          'name': 'Downloaded sample CSV',
          'properties': { timestamp: new Date() }
        })
      },
      trackTextCTA () {
        this.recordEvent({
          name: 'Clicked text editor CTA',
          properties: { timestamp: new Date() }
        })
      },
      trackRetry () {
        this.recordEvent({
          'name': 'Retried file analysis on failure',
          'properties': { timestamp: new Date() }
        })
      }
    },
    computed: {
      ...mapState(['analysisLoading', 'altId', 'contact', 'messages']),
      resultText () {
        return this.result.error ? this.result.error : `All done! You’ll receive an email with your customized report shortly.`
      },
      resultColor () {
        return this.result.error ? 'error' : 'success'
      },
      csvFileName () {
        return this.csvFile && this.csvFile.name ? this.csvFile.name : ''
      }
    },
    watch: {
      contact () {
        this.email = this.contact ? this.contact.email : this.email
      }
    }
  }
</script>
<style lang="scss" scoped>
.left-overlay {
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 444px;
  z-index: 9;
  background: linear-gradient(0deg, #2A2B36 0%, #4C0AB5 0%, #406FDF 100%);
}
.analysis-page {
  background-color: #fff;
}
.main-content {
  margin-left: 444px;
  background-color: #fff;

  .report-form-container {
    max-width: 480px;
    padding: 12px;
    margin: auto;
    min-height: calc(100vh - 52px - 84px);

    .has-csv {
      .input-fields {
        max-height: 265px;
      }
    }

    .report-form {
      width: 100%;
    }

    .description {
      max-width: 312px;
      margin: auto;
    }

    .file-input {
      display: none;
    }

    .file-name {
      max-width: calc(100% - 70px);
      display: inline;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .download-link {
      text-decoration: none;
    }

    .input-fields {
      max-height: 0;
      overflow: hidden;
      transition: max-height 200ms ease-in-out;
    }

    .upload-detail-card {
      height: 52px;
      box-shadow: 0 0 7px 0 rgba(67,71,97,0.12);
      width: 100%;
      .csv-icon {
        margin-top: -2px;
      }
      .replace {
        color: #126BFF;
        cursor: pointer;
      }
    }
    .submit-button {
      width: 100%;
    }
  }

  .footer {
    height: 84px;
    text-align: center;

    .nav-link {
      text-decoration: none;
    }
  }
}
</style>
<style lang="scss" scoped>
@import '~vuetify/src/styles/settings/_variables';
@media #{map-get($display-breakpoints, 'sm-and-down')} {
  .main-content {
    margin-left: 0;
  }
}
</style>
