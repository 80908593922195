import Vue from 'vue'
import App from './App'

import 'clevertap-dashboard-components/themes/main.scss'
import vuetify from 'clevertap-dashboard-components/plugins/vuetify'
import store from './store'
import router from './router'
import { RECAPTCHA_KEY } from './constants'
import { VueReCaptcha } from 'vue-recaptcha-v3'
import VueAnalytics from 'vue-analytics'

Vue.use(VueReCaptcha, { siteKey: RECAPTCHA_KEY })

Vue.use(VueAnalytics, {
  id: 'UA-67536578-2',
  router
})

Vue.config.productionTip = false

/* eslint-disable no-new */
new Vue({
  el: '#site',
  router,
  store,
  vuetify,
  components: { App },
  render (h) {
    return h(App, {})
  }
})
