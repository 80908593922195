import Vue from 'vue'
import Vuex from 'vuex'
import api from './api'
import { deepCopyObject } from 'clevertap-dashboard-components/util/clevertap'
import { emotions, words } from './fixture'
import messages from '@/store/i18n'

Vue.use(Vuex)

const queryString = window.location.search
const urlParams = new URLSearchParams(queryString)
const altId = urlParams.get('alt_id') || null

const host = window.location.host
let env = (host.startsWith('localhost') || host.startsWith('dev')) ? 'test' : 'prod'

const CLEVERTAP_ACCOUNT_ID = env === 'test' ? 'TEST-8R5-494-785Z' : 'W65-W94-785Z'
const clevertap = { event: [], profile: [], account: [], onUserLogin: [], notifications: [], privacy: [] }
clevertap.account.push({ 'id': CLEVERTAP_ACCOUNT_ID })
clevertap.privacy.push({ optOut: false })
// noinspection JSValidateTypes
clevertap.privacy.push({ useIP: false })
window.clevertap = clevertap

const loadCT = () => {
  const wzrk = document.createElement('script')
  wzrk.type = 'text/javascript'
  wzrk.async = true
  wzrk.src = (document.location.protocol === 'https:' ? 'https://d2r1yp2w7bby2u.cloudfront.net' : 'http://static.clevertap.com') + '/js/a.js'
  const s = document.getElementsByTagName('script')[0]
  s.parentNode.insertBefore(wzrk, s)
}
loadCT()

const defaultState = {
  error: false,
  emotions: emotions,
  words: words,
  emotionsLoading: false,
  analysisLoading: false,
  altId: altId,
  contact: null,
  messages
}

export default new Vuex.Store({
  state: Object.assign({}, defaultState),
  getters: {},
  mutations: {
    SET_ERROR (state, payload) {
      state.error = payload
    },
    SET_CONTACT (state, payload) {
      state.contact = payload
    },
    SET_EMOTIONS (state, payload) {
      if (!payload) {
        return
      }
      const emotions = []
      Object.entries(payload).forEach((entry) => {
        emotions.push({ name: entry[0], percentage: entry[1] })
      })
      emotions.sort((a, b) => {
        return b.percentage - a.percentage
      })
      state.emotions = emotions
    },
    SET_WORD_WISE (state, payload) {
      if (!payload) {
        return
      }
      state.words = payload
    },
    SET_EMOTIONS_LOADING (state, payload) {
      state.emotionsLoading = payload
    },
    SET_ANALYSIS_LOADING (state, payload) {
      state.analysisLoading = payload
    }
  },
  actions: {
    recordEvent ({ commit, dispatch }, event = {}) {
      clevertap.event.push(event.name, event.properties || {})
    },
    recordUserProfile ({ commit, dispatch }, profile = {}) {
      clevertap.profile.push(profile)
    },
    async fetchContact ({ commit }, altId) {
      let contact
      try {
        contact = await api.post('/contact', { 'alt_id': altId })
      } catch (err) {
        // no-op
      }
      commit('SET_CONTACT', contact)
    },
    async getData ({ commit, dispatch }, query = {}) {
      commit('SET_EMOTIONS_LOADING', true)
      commit('SET_ERROR', false)
      query.word_emotions = 2
      let data = {}
      try {
        data = await api.post('/emotions', query)
      } catch (err) {
        commit('SET_ERROR', err)
      }
      if (data.error) {
        commit('SET_ERROR', data.error)
      } else {
        commit('SET_EMOTIONS', data.sentence_emotions)
        commit('SET_WORD_WISE', data.word_emotions)
      }
      commit('SET_EMOTIONS_LOADING', false)
      return true
    },
    async getData_old ({ commit, dispatch }, query = {}) {
      commit('SET_EMOTIONS_LOADING', true)
      commit('SET_ERROR', false)
      const tokens = query.tokens
      delete query.tokens
      const query1 = deepCopyObject(query)
      query1.token = tokens[0]
      const query2 = deepCopyObject(query)
      query2.token = tokens[1]
      let [emotions, wordWise] = await Promise.all([
        dispatch('getEmotions', query1),
        dispatch('getWordWise', query2)
      ])
      if (emotions.error) {
        commit('SET_ERROR', emotions.error)
      } else {
        commit('SET_EMOTIONS', emotions)
      }
      if (wordWise.error) {
        commit('SET_ERROR', wordWise.error)
      } else {
        commit('SET_WORD_WISE', wordWise)
      }
      commit('SET_EMOTIONS_LOADING', false)
      return true
    },
    async getEmotions ({ commit }, query = {}) {
      query.word_emotions = 0
      try {
        return await api.post('/emotions', query)
      } catch (err) {
        commit('SET_ERROR', err)
        return null
      }
    },
    async getWordWise ({ commit }, query = {}) {
      query.word_emotions = 1
      try {
        return await api.post('/emotions', query)
      } catch (err) {
        commit('SET_ERROR', err)
        return null
      }
    },
    async createAnalysis ({ commit }, options = {}) {
      const error = { error: 'unable to complete csv upload' }
      commit('SET_ANALYSIS_LOADING', true)
      try {
        const data = Object.assign({ content_type: options.file.type }, options.data)
        const resp1 = await api.post('/analysis', data)
        if (!resp1 || resp1.error) {
          error.error = resp1.error
          commit('SET_ANALYSIS_LOADING', false)
          return error
        }
        const s3headers = {
          'Content-Type': options.file.type
        }
        const { signedUrl } = resp1
        const resp2 = await api.put(signedUrl, options.file, s3headers)
        commit('SET_ANALYSIS_LOADING', false)
        return (resp2 && !resp2.error) ? { 'success': true } : error
      } catch (err) {
        console.log(err)
        commit('SET_ANALYSIS_LOADING', false)
        return error
      }
    }
  }
})
