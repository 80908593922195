<script>
import { VForm } from 'vuetify/lib'

let _VForm = VForm

export default {
  name: 'CtForm',
  extends: _VForm
}
</script>

<style>

</style>
