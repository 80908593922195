<script>
import { VTabs } from 'vuetify/lib'

let _VTabs = VTabs

export default {
  name: 'CtTabs',
  extends: _VTabs,
  props: {
    color: {
      type: String,
      default: 'blue-grey'
    },
    activeClass: {
      type: String,
      default: 'font-weight-bold'
    }
  }
}
</script>

<style>

</style>
