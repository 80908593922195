const messages = {
  'en': {
    emotions_headline: 'Write emotionally persuasive content that converts',
    emotions_sub_heading: 'Create content that resonates and engages your audience.<br/>Enter any text to find out which emotions are present. Play around with the words to change the emotion mix and boost conversion.',
    emotions_footer_headline: 'Want even deeper insights into how emotions impact your CTR?',
    emotions_footer_subheadline: 'Get a free report with detailed insights about how your customers respond to emotions.',
    analysis_heading: 'Get your free report!',
    analysis_sub_heading: 'Upload a CSV file and fill in a few details to get your emotion and phrase analysis report.',
    analysis_sidebar_title: 'Want even deeper insights into how emotions impact your CTR?',
    analysis_sidebar_subtitle: 'Upload any text or marketing copy and get a free report with detailed insights about how your customers respond to emotions.'
  }
}
export default messages.en
export { messages }
