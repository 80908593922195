<template>
  <v-icon v-bind="$attrs" v-on="$listeners" :size="size">
    <slot></slot>
  </v-icon>
</template>
<script>
export default {
  name: 'CtIcon',
  props: {
    size: {
      type: [Number, String],
      default: 16
    }
  }
}
</script>
