import Vue from 'vue'
import Router from 'vue-router'
import Emotions from '../pages/Emotions'
import Analysis from '../pages/Analysis'

Vue.use(Router)

export default new Router({
  mode: 'history',
  routes:
    [
      {
        path: '/',
        component: Emotions
      },
      {
        path: '/analysis',
        component: Analysis
      },
      {
        path: '*',
        redirect: '/'
      }
    ]
})
