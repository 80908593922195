const emotions = [
    { 'name': 'anticipation', 'percentage': 27.22 },
  { 'name': 'fomo', 'percentage': 23.95 },
  { 'name': 'surprise', 'percentage': 18.07 },
  { 'name': 'trust', 'percentage': 15.47 },
  { 'name': 'joy', 'percentage': 15.29 }
  ]
const words = [{
  'Type': {
    'anticipation': 3.31,
    'fomo': 0.29,
    'joy': -1.39,
    'surprise': -1.92,
    'trust': -0.29
  }
}, {
  'or': null
}, {
  'paste': {
    'anticipation': 0.83,
    'fomo': -0.82,
    'joy': 0.12,
    'surprise': -0.78,
    'trust': 0.65
  }
}, {
  'your': {
    'anticipation': 2.34,
    'fomo': 1.4,
    'joy': -1.2,
    'surprise': -1.39,
    'trust': -1.15
  }
}, {
  'text': {
    'anticipation': 3.46,
    'fomo': -2.91,
    'joy': 0.79,
    'surprise': -0.78,
    'trust': -0.56
  }
}, {
  'here': null
}, {
  'Click': {
    'anticipation': -0.87,
    'fomo': 1.74,
    'joy': -0.43,
    'surprise': 0.22,
    'trust': -0.66
  }
}, {
  'on': null
}, {
  'an': null
}, {
  'emotion': {
    'anticipation': 1.7,
    'fomo': -2.05,
    'joy': -0.05,
    'surprise': -0.51,
    'trust': 0.91
  }
}, {
  'to': null
}, {
  'view': {
    'anticipation': 1.41,
    'fomo': -0.89,
    'joy': -0.35,
    'surprise': -0.69,
    'trust': 0.52
  }
}, {
  'the': null
}, {
  'words': {
    'anticipation': 0.75,
    'fomo': -2.58,
    'joy': 1.26,
    'surprise': -1.45,
    'trust': 2.03
  }
}, {
  'that': null
}, {
  'increase': {
    'anticipation': 1.3,
    'fomo': -5.56,
    'joy': 2.74,
    'surprise': 1.95,
    'trust': -0.43
  }
}, {
  'and': null
}, {
  'decrease': {
    'anticipation': -8.64,
    'fomo': 11.57,
    'joy': -1.41,
    'surprise': 4.16,
    'trust': -5.68
  }
}, {
  'the': null
}, {
  'strength': {
    'anticipation': -3.04,
    'fomo': 7.46,
    'joy': -2.14,
    'surprise': 1.51,
    'trust': -3.8
  }
}, {
  'of': null
}, {
  'that': null
}, {
  'emotion': {
    'anticipation': 1.01,
    'fomo': -0.03,
    'joy': -0.54,
    'surprise': -1.07,
    'trust': 0.64
  }
}]

export { emotions, words }
