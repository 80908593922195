<script>
import { VFileInput } from 'vuetify/lib'

let _VFileInput = VFileInput

export default {
  name: 'CtFileInput',
  extends: _VFileInput
}
</script>

<style>

</style>
