<template>
  <div class="prefix-textfield-container">
    <div class="prefix font-weight-semibold" :style="{'font-size': prefixFontSize+'px'}">{{ prefix }}</div>
    <input
      type="text"
      @input="update"
      :value="value"
      :placeholder="placeholder"
    />
  </div>
</template>
<script>
export default {
  name: 'CtPrefixTextField',
  props: {
    prefix: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    value: {
      type: String,
      default: ''
    },
    rules: {
      type: Array,
      default: () => []
    },
    prefixFontSize: {
      type: Number,
      default: 14
    }
  },
  methods: {
    update (e) {
      this.$emit('input', '' + e.target.value)
    }
  }
}
</script>
<style lang="scss" scoped>
.prefix-textfield-container {
  display: flex;
  align-items: center;
  background-color: #eeeff2;
  border-radius: 4px;
  height: 36px;
  padding: 8px;

  .prefix {
    white-space: nowrap;
    color: #171A2A;
  }

  input[type=text] {
    font-size: 12px;
    background: transparent;
    border: 0;
    outline: 0;
    box-shadow: none;
    margin: 0;
    margin-left: 8px;
    padding-left: 0;
    -webkit-appearance: none;
    overflow: hidden;
  }
}
</style>
