import { ACCOUNT_ID, ACCOUNT_NAME, USER_NAME, TIMEZONE_OFFSET_MINS, EVENTSTOREFIELDS, USER_IS_INTERNAL } from '../store/constants'
import { compressToBase64, decompressFromBase64 } from './lzs'
import lodashDeepClone from 'lodash.clonedeep'
import api from '../store/api/api'
import client, { setUseSampling } from '../store/api/client'
import moment from 'moment'
import { readCookie, isNotNullObject, addToUrl, isSamplingAccount } from '../store/api/clientUtils'

export { readCookie, isNotNullObject, addToUrl, isSamplingAccount }
export const evtMaster = deepCopyObject(window.evtMaster || {})
export const APP_LAUNCHED_ID = getEventIdFromName('App Launched')
export const APP_UNINSTALLED_ID = getEventIdFromName('App Uninstalled')
export const CONVERSION_EVENT = getConversionEvent()
export const CONVERSION_EVENT_ID = getEventIdFromName(CONVERSION_EVENT)
export const CONVERSION_PROPERTY = getConversionProperty(CONVERSION_EVENT)
export const CAN_REQUEST_CONVERSION_DATA = CONVERSION_EVENT_ID && !(CONVERSION_PROPERTY === undefined || CONVERSION_PROPERTY == null || CONVERSION_PROPERTY < 0)

const getGroupOnForConversionEvent = () => {
  // This page was written wrt the old event master.
  // So don't call cleanEvtMaster on the existing.
  // Instead, clone it and use the cloned version.
  if ((!CONVERSION_EVENT_ID && CONVERSION_EVENT_ID !== 0) || (!CONVERSION_PROPERTY && CONVERSION_PROPERTY !== 0)) {
    return EVENTSTOREFIELDS.eventProps
  }
  const clonedEvtMaster = deepCopyObject(evtMaster)
  cleanEvtMaster(clonedEvtMaster)
  if (clonedEvtMaster[CONVERSION_EVENT_ID]['props'][CONVERSION_PROPERTY].name.startsWith('Items|')) {
    return EVENTSTOREFIELDS.items
  }
  return EVENTSTOREFIELDS.eventProps
}

export const GROUP_ON_FOR_CONVERSION_EVENT = getGroupOnForConversionEvent()

var InternalUser = false

export const getUrlForQueryResult = (query) => {
  const _query = encodeURIComponent(compressToBase64(JSON.stringify(query)))
  return `/${ACCOUNT_ID}/json/fetch/query-results/?sdt=1&q=${_query}`
}

export const getQueryResult = async (query, useCache, validFor) => {
  const queryUrl = getUrlForQueryResult(query)
  let data = await api.getQueryResult(queryUrl, useCache, validFor)
  return data
}

export const getQueryResultsFromUrl = async (url, useCache, validFor) => {
  let data = await api.getQueryResult(url, useCache, validFor)
  return data
}

export const postQueryResultsFromUrl = async (url, useCache, validFor, requestData) => {
  let data = await api.postQueryResult(url, useCache, validFor, requestData)
  return data
}

export function recordNPSRating (eventProps, excludeForInternals, pushEventToCT) {
  // TODO: build function from utils.js
}

export function recordEvent (eventName, eventProps, excludeForInternals) {
  if (USER_NAME && (USER_NAME.indexOf('@wizrocket.com') > -1 || USER_NAME.indexOf('@clevertap.com') > -1)) {
    InternalUser = true
  }
  if (typeof (ACCOUNT_ID) === 'undefined') {
    return
  }
  if (typeof eventProps === 'undefined') {
    eventProps = {}
  }

  if (typeof excludeForInternals === 'undefined') {
    excludeForInternals = false
  }

  if (eventName === 'NPS Feedback' && !(excludeForInternals && InternalUser)) {
    recordNPSRating(eventProps, excludeForInternals, false)
  }

  var path = document.location.pathname
  eventProps.Page = path.substring(path.indexOf('/', 1))
  eventProps.Account = ACCOUNT_NAME
  eventProps.Email = USER_NAME
  eventProps.Referrer = document.referrer.substring(document.referrer.lastIndexOf('/') + 1, document.referrer.indexOf('html') === -1 ? document.referrer.length : document.referrer.indexOf('html') + 4)

  // Don't include the profile ID
  eventProps.Page = (endsWith(eventProps.Page, '/profile-view.html')) ? '/profile-view.html' : eventProps.Page
  eventProps.Referrer = (endsWith(eventProps.Referrer, '/profile-view.html')) ? '/profile-view.html' : eventProps.Referrer

  if (excludeForInternals && InternalUser) {
    // console.log('Internal user to be excluded for ct db record event')
    return
  }

  window.clevertap.event.push(eventName, eventProps)

  var uTypeEnum = InternalUser ? 'IN' : 'EX'
  if (USER_NAME) {
    window.clevertap.profile.push({ 'Site': { 'UserType': uTypeEnum } })
  }
}

export function recordForBiz (eventName, eventProps) {
  if (typeof eventProps === 'undefined') {
    eventProps = {}
  }
  client.get('/' + ACCOUNT_ID + '/json/metrics/record?evtName=' + eventName + '&props=' + JSON.stringify(eventProps))
}

export function recordForBizWithFeatureUsed (eventName, eventProps) {
  if (typeof eventProps === 'undefined') {
    eventProps = {}
  }
  client.post('/' + ACCOUNT_ID + '/json/metrics/recordWithFeatureUsed', JSON.stringify({
      evtName: eventName,
      props: eventProps
  }))
}

export function recordEventForDashboard (eventName, eventProps) {
  recordEvent(eventName, eventProps)
}

export function recordEventForBiz (eventName, eventProps) {
  if (typeof eventProps === 'undefined') {
    eventProps = {}
  }
  client.get('/' + ACCOUNT_ID + '/json/metrics/record?evtName=' + eventName + '&props=' + JSON.stringify(eventProps))
}

export function recordEventForDashboardAndBiz (eventName, eventProps) {
  recordEventForDashboard(eventName, eventProps)
  recordEventForBiz(eventName, eventProps)
}

export function endsWith (str, suffix) {
  return str.indexOf(suffix, str.length - suffix.length) !== -1
}

// USAGE
// const en = createEnum(['A', 'b', 'C'])
// en[1] -> 'B'
// en.A -> 0
export function createEnum (array, toUpperCase = true) {
  return Object.freeze(array
    .reduce((obj, item, index) => {
      if (typeof item === 'string') {
        item = (toUpperCase) ? item.toUpperCase() : item
        obj[obj[index] = item] = index
      }
      return obj
    }, {})
  )
}

export function extractQueryFromUrl (url) {
  // TODO: improve this logic
  const urlCompressed = (decodeURIComponent(url).split('q=')[1]).split('&')[0]
  return JSON.parse(decompressFromBase64(urlCompressed))
}

export function encodeQueryUrl (query) {
  return encodeURIComponent(compressToBase64(JSON.stringify(query)))
}

export function deepCopyObject (srcObj) {
  return lodashDeepClone(srcObj)
}

export function prettify (x, currency) {
  x = x + ''

  var sp = x.split('.')

  var a = sp[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')

  var result
  if (currency === true || (sp[0].length <= 3 && sp.length > 1)) { /* < 4 digit & decimal exist */
    result = a + (sp.length > 1 ? '.' + (parseFloat(('.' + sp[1])).toFixed(2) + '').substring(2) : '.00')
  } else {
    result = a
  }

  return result
}

export function mergeObjects (destObj, srcObj, override) {
  if (!isNotNullObject(srcObj)) {
    throw new Error('source should be an object')
  }
  if (!isNotNullObject(destObj)) {
    destObj = {}
  }
  for (let key in srcObj) {
    let value = srcObj[key]
    if (isNotNullObject(value) && !Array.isArray(value)) {
      let newDest = destObj[key]
      if (newDest === null && newDest === undefined) {
        newDest = {}
      }
      destObj[key] = mergeObjects(newDest, value, override)
    } else if (destObj.hasOwnProperty(key) && !override) {
      console.error(`Conflict during merge! Destination contains field: ${key}`)
    } else {
      destObj[key] = value
    }
  }

  return destObj
}

export function debounce (func, delay) {
  let debounceTimer
  return function (...args) {
    let context = this
    clearTimeout(debounceTimer)
    debounceTimer = setTimeout(() => func.apply(context, args), delay)
  }
}

export function getMomentObject (date, dateFormat, epoch) {
  let momentObj
  if (typeof TIMEZONE_OFFSET_MINS !== 'undefined') {
    if (date && dateFormat) {
      momentObj = moment(date, dateFormat).utcOffset(TIMEZONE_OFFSET_MINS, true)
    } else {
      if (!epoch) {
        epoch = (new Date()).getTime()
      }
      momentObj = moment(epoch).utcOffset(TIMEZONE_OFFSET_MINS)
    }
  } else {
    momentObj = moment()
  }
  return momentObj
}

export function printf (stringVal, ...replacers) {
  for (const replacer of replacers) {
    stringVal = stringVal.replace(/((?!\\).?)%s/, `$1${replacer}`)
  }
  // escape character handling
  stringVal = stringVal.replace(/\\%s/g, '%s')
  return stringVal
}

export function getEventIdFromName (name) {
  for (let key in evtMaster) {
    if (name === evtMaster[key].name) {
      return parseInt(evtMaster[key].id)
    }
  }
}

export function getEventObjectFromId (eventId) {
  for (let key in evtMaster) {
    if (eventId === evtMaster[key].id) {
      return evtMaster[key]
    }
  }
}

export function getEventObjectFromName (name) {
  for (let key in evtMaster) {
    if (name === evtMaster[key].name) {
      return evtMaster[key]
    }
  }
}

export function getEventPropertyIdFromEventId (propertyName, eventId) {
  for (let key in evtMaster) {
    if (parseInt(eventId) === parseInt(evtMaster[key].id)) {
      const props = evtMaster[key].props || {}
      for (let propKey in props) {
        if (propertyName === props[propKey].name) {
          return parseInt(props[propKey].id)
        }
      }
      break
    }
  }
}

export function getConversionEvent (eventsObj) {
  for (const evt in evtMaster) {
    if (evtMaster.hasOwnProperty(evt)) {
      var obj = evtMaster[evt]
      if (obj.isc === 1) {
        return obj.name
      }
    }
  }
  return null
}

 export function getEventDrp (evtMaster, eventName) {
  for (const evt in evtMaster) {
    if (evtMaster.hasOwnProperty(evt)) {
      const obj = evtMaster[evt]
      if (obj.name === eventName) {
        return obj.drp
      }
    }
  }
  return 365
}

export function getConversionProperty (conversionEvt) {
  for (const evt in evtMaster) {
    if (evtMaster[evt]['name'] === conversionEvt && evtMaster.hasOwnProperty(evt)) {
      const obj = evtMaster[evt]['props']
      for (const prop in obj) {
        if (obj[prop]['isp'] === 1) {
          return obj[prop]['id']
        }
      }
    }
  }
  return null
}

const _merge = (arr1, arr2) => {
  return arr1.push(...arr2)
}

export function mergeQueryWithSegment (mainQ, segmentQ, merge) {
  if (typeof segmentQ === 'undefined') {
    return
  }
  if (typeof mainQ['p'] === 'undefined') {
    mainQ['p'] = []
  }
  if (typeof mainQ['app'] === 'undefined') {
    mainQ['app'] = []
  }
  if (typeof mainQ['i'] === 'undefined') {
    mainQ['i'] = []
  }
  if (typeof mainQ['e'] === 'undefined') {
    mainQ['e'] = []
  }
  if (typeof mainQ['px'] === 'undefined') {
    mainQ['px'] = []
  }
  if (typeof mainQ['r'] === 'undefined') {
    mainQ['r'] = []
  }
  if (typeof mainQ['sq'] === 'undefined') {
    mainQ['sq'] = []
  }
  if (typeof mainQ['s'] === 'undefined') {
    mainQ['s'] = []
  }
  if (merge) {
    if (typeof segmentQ['evq']['p'] !== 'undefined') {
      _merge(mainQ['p'], segmentQ['evq']['p'])
      if (typeof mainQ['aq'] !== 'undefined' && typeof mainQ['aq']['p'] !== 'undefined') {
        _merge(mainQ['aq']['p'], segmentQ['evq']['p'])
      }
      if (typeof mainQ['tq'] !== 'undefined' && typeof mainQ['tq']['p'] !== 'undefined') {
        _merge(mainQ['tq']['p'], segmentQ['evq']['p'])
      }
    }
    if (typeof segmentQ['evq']['px'] !== 'undefined') {
      _merge(mainQ['px'], segmentQ['evq']['px'])
      if (typeof mainQ['aq'] !== 'undefined' && typeof mainQ['aq']['px'] !== 'undefined') {
        _merge(mainQ['aq']['px'], segmentQ['evq']['px'])
      }
      if (typeof mainQ['tq'] !== 'undefined' && typeof mainQ['tq']['px'] !== 'undefined') {
        _merge(mainQ['tq']['px'], segmentQ['evq']['px'])
      }
    }
    if (typeof segmentQ['evq']['app'] !== 'undefined') {
      _merge(mainQ['app'], segmentQ['evq']['app'])
    }
    if (typeof segmentQ['evq']['r'] !== 'undefined') {
      _merge(mainQ['r'], segmentQ['evq']['r'])
    }
    if (typeof segmentQ['evq']['sq'] !== 'undefined') {
      _merge(mainQ['sq'], segmentQ['evq']['sq'])
    }
    if (typeof segmentQ['evq']['e'] !== 'undefined') {
      _merge(mainQ['e'], segmentQ['evq']['e'])
    }
    if (typeof segmentQ['evq']['i'] !== 'undefined') {
      _merge(mainQ['i'], segmentQ['evq']['i'])
    }
    if (typeof segmentQ['evq']['s'] !== 'undefined') {
      _merge(mainQ['s'], segmentQ['evq']['s'])
    }
  } else {
    // override
    mainQ['p'] = segmentQ['evq']['p']
    mainQ['app'] = segmentQ['evq']['app']
    mainQ['i'] = segmentQ['evq']['i']
    mainQ['e'] = segmentQ['evq']['e']
    mainQ['px'] = segmentQ['evq']['px']
    mainQ['r'] = segmentQ['evq']['r']
    mainQ['sq'] = segmentQ['evq']['sq']
    mainQ['s'] = segmentQ['evq']['s']
    mainQ['i'] = segmentQ['evq']['i']
  }
}

export function cleanEvtMaster (__evtMaster) {
  const events = Object.keys(__evtMaster) || []
  for (let i = 0; i < events.length; i++) {
    const props = __evtMaster[events[i]]['props']
    if (props.constructor === {}.constructor) {
      continue
    }
    // props is now an array
    const nProps = {}
    for (let x = 0; x < props.length; x++) {
      // const p = {}
      // p['id'] = props[x]['id'];
      // p['name'] = props[x]['name'];
      nProps[props[x]['id']] = props[x]
    }

    // Set the new props
    __evtMaster[events[i]]['props'] = nProps
  }
}

export function getFunnelOrNames (eventNames, stepMapping) {
  var eventNamesList = []
  for (var k = 0; k < stepMapping.length; k++) {
    var eventName = eventNames[k]
    var str = (stepMapping[k] === stepMapping[k + 1]) ? ' OR ' : ''
    var currName = eventNamesList[stepMapping[k]]
    eventNamesList[stepMapping[k]] = ((typeof currName === 'undefined') ? '' : currName) + eventName + str
  }
  return eventNamesList
}

export function setSamplingMode () {
  const samplingMode = parseInt(window.SAMPLING_MODE || 0)
  setUseSampling(samplingMode)
}

export const escapeHtml = (value) => {
  return value.replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
}

// Expose tools to debug for internal user here
if (USER_IS_INTERNAL) {
  window.LZS = {
    compressToBase64,
    decompressFromBase64
  }
}

export function isNullOrEmpty (obj) {
  return (!isNotNullObject(obj) || Object.keys(obj).length === 0)
}
