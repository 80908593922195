<template>
  <div class="report-footer">
    <div class="fixed-container pa-3" ref="fixedcontainer">
      <div class="inner d-block d-md-flex align-center justify-space-between">
        <div class="description d-flex align-center">
          <div class="icon-container mr-4 d-md-flex align-center justify-center d-none">
            <ct-icon color="#ffffff" size="24">icon-upload</ct-icon>
          </div>
          <div class="text text-left">
            <div class="header t-16 font-weight-bold d-none d-md-block">{{ messages.emotions_footer_headline }}</div>
            <div class="sub-header t-14">
              {{ messages.emotions_footer_subheadline }}
              <!-- TODO: sample Report URL -->
              <a :href="sampleReportURL" target="_blank" class="sample-report-link ml-2 d-none d-md-inline">View Sample Report <ct-icon class="ml-2" color="#ffffff">cticon-new-tab</ct-icon></a>
            </div>
          </div>
        </div>
        <div class="cta">
          <ct-button class="my-4 mr-4 d-md-none sample-report-button" color="negativeBtn" :href="sampleReportURL" target="_blank" @click.native="trackSampleReportCTA">
            View Sample Report
          </ct-button>
          <ct-button color="secondaryBtn" :to="{path: 'analysis'}" @click.native="trackUploadCTA">
            Get My Free Report
            <ct-icon size="18" right class="d-none d-md-inline">mdi-chevron-right</ct-icon>
          </ct-button>
        </div>
      </div>
    </div>
    <div class="placeholder d-md-none" :style="{ height: `${footerHeight}px` }"></div>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import CtIcon from 'clevertap-dashboard-components/components/Icon/CtIcon'
import CtButton from 'clevertap-dashboard-components/components/Button/CtButton'
export default {
  name: 'ReportFooter',
  components: {
    CtIcon,
    CtButton
  },
  data: () => ({
    footerHeight: 0
  }),
  computed: {
    ...mapState(['messages']),
    sampleReportURL () {
      return '/CleverTapEmotionsReport.pdf'
    }
  },
  methods: {
    ...mapActions(['recordEvent']),
    trackUploadCTA () {
      this.recordEvent({
        name: 'Clicked upload messages CTA',
        properties: {
          timestamp: new Date()
        }
      })
    },
    trackSampleReportCTA () {
      this.recordEvent({
        name: 'Clicked sample report CTA',
        properties: {
          timestamp: new Date()
        }
      })
    }
  },
  mounted () {
    this.footerHeight = this.$refs['fixedcontainer'] ? this.$refs['fixedcontainer'].getBoundingClientRect().height : 0
  }
}
</script>
<style lang="sass" scoped>
.report-footer
  min-height: 84px
  .placeholder
    height: 100%
  .fixed-container
    position: fixed
    bottom: 0
    width: 100%
    left: 0
    background: linear-gradient(315deg, #2A2B36 0%, #4C0AB5 0%, #406FDF 100%)
    color: #fff
    .inner
      max-width: calc(100% - 64px)
      margin: auto
    .icon-container
      width: 60px
      height: 60px
      background-color: #507FFA
      border-radius: 50%
  .sample-report-link
    color: #fff
</style>
<style lang="scss" scoped>
@import '~vuetify/src/styles/settings/_variables';
@media #{map-get($display-breakpoints, 'sm-and-down')} {
  .inner {
    max-width: 100% !important;
  }
}
</style>
<style scoped>
>>> .sample-report-button.ct-button.v-btn.v-size--default.theme--light:not(.v-btn--icon):not(.v-btn--fab).v-btn--outlined {
  background-color: transparent !important;
  color: #fff !important;
}
</style>
