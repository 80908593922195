import axios from 'axios'
const host = window.location.host
let apiHost = host.startsWith('localhost')
    ? 'dev.api.emotions.clevertap.tech' : host.startsWith('dev') ? host.replace('dev', 'dev.api') : `api.${host}`
const baseURL = `https://${apiHost}`
const instance = axios.create({
  baseURL,
  timeout: 30000
})

const client = {
  get (path, params = {}) {
    return instance.get(path, { params: params })
  },
  post (path, data = {}, headers = {}) {
    return instance.post(path, data, { headers: headers })
  },
  put (path, data = {}, headers = {}) {
    return instance.put(path, data, { headers: headers })
  }
}

export default client
