<script>
import { VProgressCircular } from 'vuetify/lib'

let _VProgressCircular = VProgressCircular

export default {
  name: 'CtProgressCircular',
  extends: _VProgressCircular
}
</script>

<style>

</style>
