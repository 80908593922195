// Do not add dependency from store or utils here. No one would ever know when things get cyclic because of this.
import axios from 'axios'
import Vue from 'vue'
import { addToUrl, isNotNullObject, isSamplingAccount } from './clientUtils'

// initialize cache and sampling from sessionStorage to keep it consistent with the old code
let useCache = 1

// Copy the uc=0 state from the local storage once, and then reset local storage
if (typeof sessionStorage !== 'undefined') {
  if (sessionStorage.getItem('use_cache') === '0') {
    useCache = 0
    sessionStorage.setItem('use_cache', '1') // reset
    // console.info('Cache disabled for this session')
  }
}

let useSampling = 0

// Create a reactive state for the client
export const clientState = Vue.observable({
  approxResult: false,
  cacheTs: 0,
  useCache,
  useSampling
})

export const setUseCache = (use) => {
  clientState.useCache = use
  if (typeof sessionStorage !== 'undefined') {
    sessionStorage.setItem('use_cache', use)
  }
}

export const setUseSampling = (use = 0) => {
  // apply sampling only if account is in the list.
  const samplingMode = parseInt(use)
  if (isSamplingAccount() && !isNaN(samplingMode)) {
    clientState.useSampling = samplingMode
  } else {
    clientState.useSampling = 0
  }
}

const getSamplingValue = () => {
  // This is based on legacy code in dashboard wzrk-jquery-overrides.js
  const analyticsMode = parseInt(localStorage.getItem(''))
  const samplingMode = parseInt(window.SAMPLING_MODE)
  const isSamplingValid = parseInt(sessionStorage.getItem('is_sampling_valid'))
  if (!isNaN(analyticsMode) && analyticsMode === 2) {
    return 2
  } else if (!isNaN(isSamplingValid) && isSamplingValid === 1 && !isNaN(samplingMode) && samplingMode === 1) {
    return 1
  }
  // New apps should use `SET_USE_SAMPLING` mutation in account module.
  return clientState.useSampling
}

export const setCacheTs = (cacheTs = 0) => {
  clientState.cacheTs = cacheTs
}

const refreshPage = () => {
  window.location = window.location.href // should cause a login, if needed. else continue.
}

const instance = axios.create({
  timeout: 50000,
  xsrfCookieName: 'csrf',
  xsrfHeaderName: 'X-CleverTap-CSRF-Token'
})

instance.interceptors.response.use(response => {
  if (response && response.data && response.data === 'refreshPage();') { // I know, ridiculous but that how the old api system works, if session times out or whatever api responds this way
    refreshPage()
    return {}
  }
  let responseData = response.data
  if ((isNotNullObject(responseData) && responseData.approxResult) || (Array.isArray(responseData) && isNotNullObject(responseData[responseData.length - 1]) && responseData[responseData.length - 1].approxResult)) {
    clientState.approxResult = true
  }
  if (isNotNullObject(responseData) && responseData['response_format'] === 'complex') {
    let outData = responseData.response
    if (responseData['cache_ts'] && responseData['cache_ts'] > 0) {
      // skipping the less than check, because we are not refreshing pages in future.
      // To know what the above comment means refer wzrk-jquery-overrides.js#128 in dashboard.
      clientState.cacheTs = responseData['cache_ts']
      response.cacheTs = responseData['cache_ts']
    }
    response.data = outData
  }

  return response
}, error => {
  return error
})

const configurePath = (path) => {
  // reset the approxResult before every request that is made
  if (path.indexOf('/json/datetime') === -1) {
    clientState.approxResult = false
  }

  const useSampling = getSamplingValue() // clientState.useSampling
  if (useSampling > 0) {
    path = addToUrl(path, 'us=' + useSampling)
  }
  const useCache = clientState.useCache
  path = addToUrl(path, 'uc=' + useCache)
  return path
}

const client = {
  get (path, params = {}, options = {}) {
    options = { ...options, params }
    path = configurePath(path)
    return instance.get(path, options)
  },
  post (path, data = {}, headers = {}, options = {}) {
    options = { ...options, headers }
    path = configurePath(path)
    return instance.post(path, data, options)
  },
  put (path, data = {}, headers = {}, options = {}) {
    options = { ...options, headers }
    path = configurePath(path)
    return instance.put(path, data, options)
  },
  delete (path, data = {}, headers = {}, options = {}) {
    options = { ...options, headers }
    path = configurePath(path)
    return instance.delete(path, options)
  },
  getS3 (path, params = {}, options = {}) {
    options = { ...options, params }
    return instance.get(path, options)
  },
  putS3 (url, data, options) {
    return instance.put(url, data, options)
  },
  getQueryResult (path, useCache) {
    if (!useCache) {
      setUseCache(0)
    }
    path = configurePath(path)
    // reset to use cache after the query is formed so that next jquery.ajax query is not affected.
    if (!useCache) {
      setUseCache(1)
    }
    // disable timeout for es queries
    return instance.get(path, { timeout: 0 })
  },
  postQueryResult (path, data = {}, headers = {}, useCache) {
    if (!useCache) {
      setUseCache(0)
    }
    path = configurePath(path)
    // reset to use cache after the query is formed so that next jquery.ajax query is not affected.
    if (!useCache) {
      setUseCache(1)
    }
    // disable timeout for es queries
    return instance.post(path, data, { headers: headers, timeout: 0 })
  }
}

export default client
