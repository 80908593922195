import Plugin from '@ckeditor/ckeditor5-core/src/plugin'

class AnalyzeEditorPlugin extends Plugin {
  init () {
    this.editor.ui.view.editable.extendTemplate({
      attributes: {
        style: {
          height: '252px',
          border: 'none',
          boxShadow: 'none',
          fontSize: '24px',
          padding: '0 40px',
          marginLeft: '-25px',
          width: 'calc(100% + 50px)',
          backgroundColor: 'transparent'
        }
      }
    })
  }
}
export default AnalyzeEditorPlugin
