<template>
  <v-app id="site">
    <v-app-bar flat app absolute height="52px" class="app-bar wrapper" max-width="1280px">
      <a class="ct-logo ml-5" href="https://clevertap.com">
        <img src="@/assets/logo.svg"/>
      </a>
      <ct-spacer/>
      <div class="button-container mr-5">
        <span class="d-none d-md-inline">Love what you see? Share on</span>
        <ct-button
          icon
          small
          class="share-icon twitter"
          :href="`https://twitter.com/intent/tweet?text=${socialText}`"
          target="_blank">
         <ct-icon color="#434761" size="15">icon-twitter</ct-icon>
        </ct-button>
        <ct-button
          icon
          small
          class="share-icon facebook"
          :href="`https://www.facebook.com/sharer/sharer.php?u=${emotionsAppURL}&t=CleverTap%20Emotions%20Analyzer`"
          target="_blank">
         <ct-icon color="#434761" size="15">icon-facebook</ct-icon>
        </ct-button>
        <ct-button
          icon
          small
          :href="`https://www.linkedin.com/sharing/share-offsite/?url=${emotionsAppURL}`"
          target="_blank"
          class="share-icon linkedin">
          <ct-icon color="#434761" size="16">icon-linkedin</ct-icon>
        </ct-button>
      </div>
    </v-app-bar>
    <v-main>
      <div class="wrapper">
        <router-view></router-view>
      </div>
    </v-main>
    <v-snackbar v-model="snackbar" :timeout="snackTimeout" top>
      <div>
        <v-icon class="mr-2" size="16" color="error snack-icon" v-if="snackColor==='error'">mdi-close-circle</v-icon>
        <v-icon class="mr-2" size="16" color="success snack-icon" v-if="snackColor==='success'">mdi-check-circle</v-icon>
        <span v-html="snackText"></span>
      </div>
      <v-btn @click="snackbar = false" icon class="close-snackbar" height="24" width="24" small><v-icon class="close-snackbar-icon" size="16" color="#4E526F">mdi-close</v-icon></v-btn>
    </v-snackbar>
  </v-app>
</template>

<script>
  import { EventBus, Events } from './EventBus'
  import { CtSpacer } from 'clevertap-dashboard-components/components/Grid/GridSystem'
  import CtIcon from 'clevertap-dashboard-components/components/Icon/CtIcon'
  import CtButton from 'clevertap-dashboard-components/components/Button/CtButton'
  import { mapActions } from 'vuex'
  export default {
    name: 'App',
    components: {
      CtSpacer,
      CtIcon,
      CtButton
    },
    mounted () {
      EventBus.$on(Events.SHOW_SNACKBAR, this.handleShowSnackbar)
      window.addEventListener('beforeunload', this.exitHandler)
      this.initTime = new Date()
    },
    data () {
      return {
        snackbar: false,
        snackTimeout: 5000,
        snackColor: 'success',
        snackText: '',
        initTime: new Date()
      }
    },
    methods: {
      ...mapActions(['recordEvent']),
      handleShowSnackbar ({ color, text }) {
        this.snackColor = color
        this.snackText = text
        this.snackbar = true
      },

      exitHandler () {
        // Called after the page is closed/navigated away from. Async calls may fail
        const now = new Date()
        this.recordEvent({
          name: 'Exited',
          properties: {
            'Timespent': (now.getTime() - this.initTime.getTime()) / 1000
          }
        })
      }
    },

    computed: {
      socialText () {
        // The text that is shared on social media
        return `Try out this emotion analysis tool! Quickly type in any copy and see what emotions are showing up - joy, surprise, anticipation, trust, FOMO. Super relevant for writing copy that resonates, especially today. ${this.emotionsAppURL}`
      },

      emotionsAppURL () {
        return window.location.href
      }
    }
  }
</script>

<style lang="scss">
  #site.theme--light .v-main {
    background-color: #fff !important;
  }
  #site .v-toolbar__content {
    padding: 0;
  }
  .v-snack--top {
    top: 58px;
  }
  .v-application {
    min-width: 320px;
  }
</style>

<style scoped lang="scss">
  .v-snack__content .close-snackbar.v-btn {
    padding: 0;
    margin-left: 16px;
  }
  .snack-icon {
    margin-top: -2px;
  }
  .app-bar {
    background-color: #fff !important;
    margin: auto;
  }
  .ct-logo {
    display: inline-block;
    width: 135px;
    height: 30px;

    img {
      height: 100%;
    }
  }
  .button-container {
    justify-content: space-evenly;
    align-items: center;
    font-size: 11px;
    color: #434761;
    height: 28px;
  }
</style>
