<script>
import { VCard } from 'vuetify/lib'

let _VCard = VCard

export default {
  name: 'CtCard',
  extends: _VCard
}
</script>
