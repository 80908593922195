<template>
  <div class="ct-textbox">
    <div class="label mb-1 t-12 font-weight-medium" v-if="label">{{ label }}</div>
    <slot name="labelSlot" v-else-if="!!this.$slots.labelSlot"></slot>
    <v-text-field
      ref="field"
      @input="update"
      :value="value"
      :placeholder="placeholder"
      :persistentHint="persistentHint"
      :singleLine="singleLine"
      :filled="filled"
      :dense="dense"
      :items="items"
      :hint="hint"
      :disabled="disabled"
      :counter="counter"
      :rules="defaultRules"
      v-on="$listeners"
      v-bind="$attrs"
    >
      <template v-for="slot in Object.keys($scopedSlots)" v-slot:[slot]="scope">
        <slot :name="slot" v-bind="scope"/>
      </template>
      <slot v-for="(_, name) in $slots" :name="name" :slot="name" />
      <template v-slot:prepend-inner v-if="isSearch"><ct-icon class="mt-1"  size="16">cticon-search</ct-icon></template>
    </v-text-field>
  </div>
</template>
<script>
import CtIcon from '../Icon/CtIcon'
export default {
  name: 'CtTextfield',
  components: { CtIcon },
  props: {
    persistentHint: {
      type: Boolean,
      default: true
    },
    isSearch: {
      // The text field is used as a searchbar (adds search icon)
      type: Boolean,
      default: false
    },
    singleLine: {
      type: Boolean,
      default: true
    },
    filled: {
      type: Boolean,
      default: true
    },
    dense: {
      type: Boolean,
      default: true
    },
    items: {
      type: Array,
      default: () => ([])
    },
    hint: {
      type: String,
      default: ''
    },
    label: {
      type: String
    },
    disabled: {
      type: Boolean,
      default: false
    },
    counter: {
      type: [Boolean, String],
      default: false
    },
    placeholder: {
      type: String,
      default: ''
    },
    value: {
      type: [String, Number, Boolean],
      default: ''
    },
     rules: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    defaultRules () {
      if (this.counter) {
        return [
          ...this.rules,
          value => value.length <= this.counter || `Max ${this.counter} characters allowed`
        ]
      }
      return this.rules
    }
  },
  methods: {
    update (newValue) { this.$emit('input', newValue) },
    blur () {
      if (this.$refs.field) {
        this.$refs.field.blur()
      }
    },
    focus () {
      if (this.$refs.field) {
        this.$refs.field.focus()
      }
    }
  }
}
</script>
<style lang="scss">
.ct-textbox {
  .v-input {

    &__slot {
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      overflow: hidden;
    }

    &--is-disabled {
      .v-input__slot{
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        overflow: visible;
      }
    }

    &--is-focused, &--is-disabled, &.error--text {
      .v-input__slot {
        &::before {
          bottom: 0;
          border-top-width: 2px;
          border-radius: 0 0 4px 4px;
        }

        &::after {
          bottom: 0;
        }
      }
    }
  }
}
</style>
