<template>
  <page-layout>
    <div class="emotions-page pa-8">
      <template>
        <div class="title-container">
          <div class="page-title t-28 font-weight-bold">{{ messages.emotions_headline }}</div>
          <div class="page-subtitle mt-3 t-16" v-html="messages.emotions_sub_heading"></div>
        </div>
        <div class="editor-container mt-2">
          <editor></editor>
        </div>
      </template>
    </div>
    <template #footer>
      <ReportFooter />
    </template>
  </page-layout>
</template>

<script>
import PageLayout from './PageLayout'
import { EventBus, Events } from '../EventBus'
import { CtRow, CtCol } from 'clevertap-dashboard-components/components/Grid/GridSystem'
import CtIcon from 'clevertap-dashboard-components/components/Icon/CtIcon'
import Editor from '../components/editor/Editor'
import ReportFooter from '@/components/ReportFooter'
import { mapState, mapActions } from 'vuex'

export default {
  name: 'Emotions',
  components: {
    PageLayout,
    CtRow,
    CtCol,
    CtIcon,
    Editor,
    ReportFooter
  },
  computed: {
    ...mapState(['error', 'messages'])
  },
  methods: {
    ...mapActions(['recordEvent'])
  },
  watch: {
    error (val) {
      if (val) {
        EventBus.$emit(Events.SHOW_SNACKBAR, { color: 'error', text: val })
      }
    }
  },
  mounted () {
    this.recordEvent({
      name: 'Page visited',
      properties: {
        timestamp: new Date()
      }
    })
  }
}
</script>
<style lang="scss" scoped>
@import '~vuetify/src/styles/settings/_variables';
@media #{map-get($display-breakpoints, 'sm-and-down')} {
  .emotions-page {
    padding: 16px !important;
  }
  .page-title {
    font-size: 20px !important;
    line-height: 28px;
    font-weight: 600 !important;
  }
  .page-subtitle {
    font-size: 12px !important;
    line-height: 16px;
    margin-bottom: 16px;
  }
}
</style>
<style scoped lang="scss">
  .analysis-button:hover:before {
    background-color: transparent;
  }
  .analysis-button:before {
    display: none;
  }
  .nav-link {
    text-decoration: none;
  }
  .increase {
    color: $color_green;
  }
  .decrease {
    color: $color_red;
  }
</style>
<style lang="scss">
.emotion-word {
  cursor: pointer;
  background-color: #fff;

  &:hover {
    &.positive {
      background-color: #E6FFE6;
    }
    &.negative {
      background-color: #FFE6E9;
    }
  }
}
.grecaptcha-badge { opacity:0;}
</style>
