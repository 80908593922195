// helper functions for client.js
// NOTE: this file should not have any dependencies
export const readCookie = function (name) {
  var nameEQ = name + '='
  var ca = document.cookie.split(';')
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i]
    while (c.charAt(0) === ' ') {
      c = c.substring(1, c.length)
    }
    if (c.indexOf(nameEQ) === 0) {
      return c.substring(nameEQ.length, c.length)
    }
  }
  return null
}

export const addToUrl = function (url, keyEqualsVal) {
  var separator = url.indexOf('?') > -1 ? '&' : '?'
  url += (separator + keyEqualsVal)
  return url
}

export const isNotNullObject = (obj) => {
  if (obj && typeof obj === 'object') {
    return true
  }
  return false
}

const listSamplingAccounts = [
  'W8R-KK8-W74Z', '877-958-874Z', '846-958-874Z', 'WW5-K94-874Z', '48K-8WW-754Z',
  '8W4-98R-484Z', '6ZW-876-6W5Z', '464-6WW-964Z', 'WW6-Z6K-RW5Z', '869-K6K-564Z',
  '844-6K7-984Z', 'WR9-9W4-994Z', '86K-47R-794Z', '865-Z68-874Z', '8W8-KR8-484Z',
  'W4R-49K-494Z', '84R-797-594Z', '6ZW-45W-W84Z', 'W88-RW6-864Z', '48K-4R4-K84Z',
  '4W9-958-874Z', '84R-797-594Z', 'RK4-47R-98KZ', '846-KR9-6W5Z', '886-W7Z-KZ5Z',
  'R97-ZRK-6W5Z', '865-54R-KZ5Z', '44Z-R49-KZ5Z', '457-87K-4Z5Z', '46R-97K-4Z5Z',
  '48Z-56Z-7Z5Z', '4W9-956-5Z5Z', '8R5-874-9R5Z', '8W6-695-WK5Z'
]

export const isSamplingAccount = () => {
  // Get account id directly from window to avoid any further circular dependencies
  const accountId = window.__ACCOUNT_ID || ''
  return listSamplingAccounts.includes(accountId)
}
