<script>
import { VContainer } from 'vuetify/lib'

let _VContainer = VContainer

export default {
  name: 'CtContainer',
  extends: _VContainer
}
</script>

<style>

</style>
