import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import 'material-design-icons-iconfont/dist/material-design-icons.css' // TODO temp
import '@mdi/font/css/materialdesignicons.css' // TODO
import '../themes/ct-fonts.scss'
// import CT_ICONS from './icons'

Vue.use(Vuetify)

const ctTheme = {
  primary: '#126BFF',
  secondary: '#434761',
  secondaryBtn: '#126BFF',
  errorBtn: '#FF5353',
  negativeBtn: '#63698f',
  toggleBtn: '#ECEDF2',
  accent: '#E2EDFE',
  error: '#FF5252',
  info: '#2196F3',
  success: '#4CAF50',
  warning: '#FFC107',
  base: '#B2B5CA',
  darken1: '#63698F',
  darken2: '#434761',
  lighten1: '#D0D2E1',
  anchor: '#085ce7'
}

const options = {
  icons: {
    iconfont: 'mdi'
  },
  theme: {
    themes: {
      light: ctTheme,
      dark: ctTheme
    },
    options: {
      customProperties: true
    }
  }
} // TODO add theming options
export default new Vuetify(options)
