<script>
import { VLayout } from 'vuetify/lib'

let _VLayout = VLayout

export default {
  name: 'CtLayout',
  extends: _VLayout
}
</script>

<style>

</style>
