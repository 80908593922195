<script>
import { VTab } from 'vuetify/lib'

let _VTab = VTab

export default {
  name: 'CtTab',
  extends: _VTab,
  props: {
    ripple: {
      type: [Boolean, Object],
      default: false
    }
  }
}
</script>

<style>

</style>
