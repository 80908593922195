import Plugin from '@ckeditor/ckeditor5-core/src/plugin'

import EmotionEditing from './emotionediting'
import EmotionUI from './emotionui'

export default class Emotion extends Plugin {
  static get requires () {
    return [ EmotionEditing, EmotionUI ]
  }
}
