<script>
import { VCarousel } from 'vuetify/lib'

let _VCarousel = VCarousel

export default {
  name: 'CtCarousel',
  extends: _VCarousel
}
</script>

<style>

</style>
