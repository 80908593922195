import Plugin from '@ckeditor/ckeditor5-core/src/plugin'

class EditorPlugin extends Plugin {
  init () {
    this.editor.ui.view.editable.extendTemplate({
      attributes: {
        style: {
          height: '272px',
          border: 'none',
          boxShadow: 'none',
          fontSize: '24px'
        }
      }
    })
  }
}
export default EditorPlugin
