<template>
  <div v-if="show" class="ct-loader">
    <ct-progress-circular color="primary" indeterminate :size="size" :width="width"></ct-progress-circular>
  </div>
</template>

<script>
  import CtProgressCircular from '../Progress/CtProgressCircular'

  export default {
    name: 'CtLoader',
    components: {
      CtProgressCircular
    },
    props: {
      show: {
        type: Boolean,
        default: false
      },
      size: {
        type: [Number, String],
        default: 32
      },
      width: {
        type: [Number, String],
        default: 4
      }
    }
  }
</script>

<style scoped>
  .ct-loader {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
  }
</style>
