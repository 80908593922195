<template>
  <div class="analysis-sidebar pa-5 d-flex justify-space-between">
    <div class="logo mt-1"><img src="@/assets/clevertap-white.svg"/></div>
    <div class="carousel">
      <MiniCarousel cycle/>
    </div>
    <div class="help-text text-center">
      <div class="main-title t-24 font-weight-bold mb-4">{{ messages.analysis_sidebar_title }}</div>
      <div class="subtitle t-18 mb-4">{{ messages.analysis_sidebar_subtitle }}</div>
      <div class="sample-report mb-12">
        <a :href="sampleReportURL" target="_blank" class="t-18 sample-report-link font-weight-semibold">View Sample Report <ct-icon class="ml-2" color="#ffffff">cticon-new-tab</ct-icon></a>
      </div>
    </div>
  </div>
</template>
<script>
import MiniCarousel from './MiniCarousel'
import CtIcon from 'clevertap-dashboard-components/components/Icon/CtIcon'
import { mapState } from 'vuex'
export default {
  name: 'AnalysisSidebar',
  components: {
    MiniCarousel,
    CtIcon
  },
  data: () => ({
  }),
  computed: {
    ...mapState(['messages']),
    sampleReportURL () {
      return '/CleverTapEmotionsReport.pdf'
    }
  }
}
</script>
<style lang="scss" scoped>
  .logo {
    width: 135px;
    height: 30px;

    img {
      height: 100%;
    }
  }
  .analysis-sidebar {
    flex-direction: column;
    color: #fff;
    height: 100%;
  }
  .sample-report-link {
    color: #fff;
  }
</style>
