import Plugin from '@ckeditor/ckeditor5-core/src/plugin'
import { toWidget, viewToModelPositionOutsideModelElement } from '@ckeditor/ckeditor5-widget/src/utils'
// import DomEventObserver from '@ckeditor/ckeditor5-engine/src/view/observer/domeventobserver'
import Widget from '@ckeditor/ckeditor5-widget/src/widget'
// import EmotionCommand from './emotioncommand'
import './theme/emotion.css'

/*
class MouseEnterObserver extends DomEventObserver {
  constructor (view) {
    super(view)
    this.domEventType = 'mouseover'
  }
  onDomEvent (domEvent) {
    this.fire('ct:mouseover', domEvent)
  }
}
 */

export default class EmotionEditing extends Plugin {
  static get requires () {
    return [ Widget ]
  }

  init () {
    this._defineSchema()
    this._defineConverters()
    // this.editor.commands.add('emotion', new EmotionCommand(this.editor))
    this.editor.editing.mapper.on(
        'viewToModelPosition',
        viewToModelPositionOutsideModelElement(this.editor.model, viewElement => viewElement.hasClass('emotion'))
    )
    /*
    this.editor.editing.view.addObserver(MouseEnterObserver)

    this.editor.listenTo(this.editor.editing.view.document, 'ct:mouseover', (e, data) => {
      if (data.target.hasClass('emotion')) {
        const element = data.target
        const color = element.getAttribute('color')
        const index = element.getAttribute('data-idx')
        const emotion = element.getAttribute('data-emotion')
        const impact = element.getAttribute('data-impact')
        const word = element.getChild(0).data
      }
    })
     */
  }

  _defineSchema () {
    const schema = this.editor.model.schema
    schema.register('emotion', {
      allowWhere: '$text',
      isInline: true,
      isObject: true,
      allowAttributes: [ 'word', 'color', 'index', 'emotion', 'impact' ]
    })
  }
  _defineConverters () {
    const conversion = this.editor.conversion

    conversion.for('upcast').elementToElement({
      view: {
        name: 'span',
        classes: ['emotion']
      },
      model: (viewElement, modelWriter) => {
        const word = viewElement.getChild(0).data
        const color = viewElement.getAttribute('color')
        const index = viewElement.getAttribute('data-idx')
        const emotion = viewElement.getAttribute('data-emotion')
        const impact = viewElement.getAttribute('data-impact')
        return modelWriter.createElement('emotion', { word, color, index, emotion, impact })
      }
    })
    conversion.for('editingDowncast').elementToElement({
      model: 'emotion',
      view: (modelItem, viewWriter) => {
        const widgetElement = createEmotionView(modelItem, viewWriter)
        return toWidget(widgetElement, viewWriter)
      }
    })

    conversion.for('dataDowncast').elementToElement({
      model: 'emotion',
      view: createEmotionView
    })

    function createEmotionView (modelItem, viewWriter) {
      const word = modelItem.getAttribute('word')
      const color = modelItem.getAttribute('color')
      const index = modelItem.getAttribute('index')
      let emotion = modelItem.getAttribute('emotion')
      emotion = emotion === 'fomo' ? 'FOMO' : emotion.charAt(0).toUpperCase() + emotion.slice(1)
      const impact = modelItem.getAttribute('impact')
      let hoverClass = 'neutral'
      if (impact && impact > -100) {
        hoverClass = impact < 0 ? 'negative' : 'positive'
      }
      const impactStatement =
          impact === null ? `is immaterial to ${emotion}` : (parseInt(impact) < -100) ? `was not analyzed` : `contributes ${impact}% to ${emotion}`
      const emotionView = viewWriter.createContainerElement('span', {
        class: `emotion-word ${hoverClass}`,
        style: `color:${color};`,
        'data-tooltip': `'${word}' ${impactStatement}`,
        'data-tooltip-position': 'bottom',
        'data-idx': index,
        'data-emotion': emotion,
        'data-impact': impact
      })

      // Insert the emotion word (as a word).
      const innerText = viewWriter.createText(word)
      viewWriter.insert(viewWriter.createPositionAt(emotionView, 0), innerText)
      return emotionView
    }
  }
}
