<script>
  import { VBtnToggle } from 'vuetify/lib'

  let _VBtnToggle = VBtnToggle // Deep Clone Maybe?

  // Delete any Properties for base component if needed

  export default {
    name: 'CtButtonToggle',
    extends: _VBtnToggle,
    props: {}
  }
</script>

<style lang="scss">
  .ct-button {
    &.v-btn {
      &.v-btn--contained {
        &.v-size--default {
          &.toggleBtn {
            color: #434761;
            &.v-item--active {
              &.v-btn--active {
                background-color: #DCDEE9 !important;
              }
            }
          }
        }
      }
    }
  }
</style>
