<template>
  <div class="carousel-container">
    <ct-carousel
      height="320"
      :cycle="cycle"
      hide-delimiter-background
      :show-arrows="false"
    >
      <ct-carousel-item
        v-for="(slide) in carouselItems"
        :key="slide.title"
      >
        <div class="text-center">
          <img :src="slide.image" />
          <div class="slider-title t-14 mt-8">{{ slide.title }}</div>
        </div>
      </ct-carousel-item>
    </ct-carousel>
    <div class="switcher">
      <div class="dot" v-for="slide in carouselItems" :key="`dot-${slide.title}`"></div>
    </div>
  </div>
</template>
<script>
import { CtCarousel, CtCarouselItem } from 'clevertap-dashboard-components/components/Carousel'
export default {
  name: 'MiniCarousel',
  components: {
    CtCarousel,
    CtCarouselItem
  },
  props: {
    cycle: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    activeIndex: 1,
    carouselItems: [
      {
        image: '/carousel-images/1.svg',
        title: 'Emotion Distribution'
      },
      {
        image: '/carousel-images/2.svg',
        title: 'Number Of Messages By Emotions'
      },
      {
        image: '/carousel-images/3.svg',
        title: 'Phrase Analysis By CTR'
      }
    ]
  }),
  computed: {
    carouselPosition () {
      return this.activeIndex * 444
    }
  }
}
</script>
<style lang="scss" scoped>
  img {
    box-shadow: 0 10px 10px 0 rgba(67,71,97,0.36);
  }
  .carousel-container {
    overflow: hidden;
  }
  .mini-carousel {
    display: flex;
    max-width: 344px;
    color: #fff;
    margin: auto;
    position: relative;
  }
  .carousel-item {
    text-align: center;
    width: 344px;
    margin-right: 100px;
  }
</style>
<style scoped>
>>> .v-carousel__controls__item.v-btn--active .v-icon {
  color: #FFFFFF;
}
>>> .v-carousel__controls__item .v-icon {
  font-size: 10px !important;
  color: #352996;
  opacity: 1 !important;
}
>>> .v-carousel__controls__item {
  width: 28px;
  margin: 0 !important;
}
>>> .v-carousel__controls__item::before {
  display: none;
}
</style>
