<script>
import { VTabsItems } from 'vuetify/lib'

let _VTabsItems = VTabsItems

export default {
  name: 'CtTabsItems',
  extends: _VTabsItems
}
</script>

<style>

</style>
