<script>
import { VTabsSlider } from 'vuetify/lib'

let _VTabsSlider = VTabsSlider

export default {
  name: 'CtTabsSlider',
  extends: _VTabsSlider
}
</script>

<style>

</style>
