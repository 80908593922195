<template>
  <v-btn class="ct-button"
         v-on="$listeners"
         v-bind="$attrs"
         :color="color"
         :outlined="_outlined"
          :ripple="false">
    <slot></slot>
    <template #loader>
      <slot name="loader"></slot>
    </template>
  </v-btn>
</template>
<script>
  import { VBtn } from 'vuetify/lib'

  export default {
    name: 'CtButton',
    components: {
      VBtn
    },
    props: {
      color: {
        type: String,
        validator: function (value) {
          // The value must match one of these strings
          return ['primary', 'secondaryBtn', 'negativeBtn', 'errorBtn', 'toggleBtn'].indexOf(value) !== -1
        },
        default: 'primary'
      }
    },
    computed: {
      _outlined () {
        switch (this.color) {
          case 'secondaryBtn' :
          case 'negativeBtn':
            return true
          default:
            return false
        }
      }
    }
  }
</script>
<style lang="scss">
  .ct-button {
    &.v-btn {
      text-transform: capitalize;
      color: #fff;
      box-sizing: border-box;
      &.v-size--default {
        &.theme--light:not(.v-btn--icon):not(.v-btn--fab) {
          padding: 8px 16px!important;
          &.v-btn--disabled {
            color: #fff !important;
            background-color: #D1D2D8 !important;
          }
          &.v-btn--outlined {
            background-color: #fff !important;
            &.v-btn--disabled {
              color: #D1D2D8 !important;
            }
          }
        }
      }
      &.errorBtn {
        span {
          color: #fff;
        }
      }
    }
  }
</style>
