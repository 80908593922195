<script>
import { VCarouselItem } from 'vuetify/lib'

let _VCarouselItem = VCarouselItem

export default {
  name: 'CtCarouselItem',
  extends: _VCarouselItem
}
</script>

<style>

</style>
