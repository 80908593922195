<script>
import { VTabItem } from 'vuetify/lib'

let _VTabItem = VTabItem

export default {
  name: 'CtTabItem',
  extends: _VTabItem
}
</script>

<style>

</style>
