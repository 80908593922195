<template>
  <section class="page">
    <div class="page-main">
      <slot></slot>
    </div>
    <div class="page-footer d-flex - align-center justify-center">
      <slot name="footer"></slot>
    </div>
  </section>
</template>

<script>
  export default {
    name: 'PageLayout'
  }
</script>

<style scoped>
  .page {
    max-width: 1280px;
    margin: auto;
  }
  .page-main {
    background-color: #f9f9fb;
  }
  .page-footer {
    text-align: center;
  }
</style>
