<template>
  <div class="ct-selectbox" ref="container">
    <div class="label mb-1 t-12 font-weight-medium" v-if="label">{{ label }}</div>
    <slot name="labelSlot" v-else-if="!!this.$slots.labelSlot"></slot>
    <v-select
      @input="handleInput"
      :menu-props="{ bottom: true, contentClass: contentClass, offsetY: true, maxWidth: containerWidth > 0 ? containerWidth : undefined }"
      :appendIcon="appendIcon"
      :persistentHint="persistentHint"
      :singleLine="singleLine"
      :filled="filled"
      :dense="dense"
      :items="items"
      :hint="hint"
      :item-value="itemValue"
      :return-object="returnObject"
      :error-messages="errorMessages"
      :multiple="multiple"
      :disabled="disabled"
      :value="selectedItems"
      :counter="counter"
      :item-disabled="_itemDisabled"
      v-on="$listeners"
      v-bind="$attrs"
    >
      <template v-for="slot in Object.keys($scopedSlots)" v-slot:[slot]="scope">
        <slot :name="slot" v-bind="scope"/>
      </template>
      <slot v-for="(_, name) in $slots" :name="name" :slot="name" />
      <template v-slot:prepend-item v-if="multiple && !hasCounter">
        <v-list-item
          ripple
          @click="toggle"
        >
          <v-list-item-action>
            <v-icon :color="selectedItems.length === items.length ? 'primary' : ''">{{ icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Select All</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
      </template>
      <template #append-item v-if="hasCounter">
        <div class="dropdown-counter">
          <v-divider/>
          <div class="px-3 py-1 d-flex justify-end t-12 font-weight-regular blue-grey--text text--lighten-2"> Added: {{selectedItems.length}}/{{_counter}} </div>
        </div>
      </template>
    </v-select>
  </div>
</template>
<script>
import dropdownMixin from '../mixins/dropdownMixin'

export default {
  name: 'CtSelect',
  mixins: [dropdownMixin],
  props: {
    value: null,
      contentClass: {
        type: String
      }
  },
  data: () => ({
    containerWidth: 200
  }),
  methods: {
    handleInput (newValue) {
      this.selectedItems = newValue
    }
  },
  watch: {
    value: {
      handler (newValue, oldValue) {
        this.selectedItems = newValue
      },
      immediate: true
    }
  },
  async mounted () {
    await this.$nextTick() // Does not give a correct value synchronously
    this.containerWidth = this.$refs.container ? this.$refs.container.clientWidth : 200
  }
}
</script>
<style lang="scss">
.ct-selectbox {
  .v-input {
    &__slot {
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      overflow: hidden;
    }

    &--is-disabled {
      .v-input__slot{
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
        overflow: visible;
      }
    }

    &--is-focused, &--is-disabled, &.error--text {
      .v-input__slot {
        &::before {
          bottom: 0;
          border-top-width: 2px;
          border-radius: 0 0 4px 4px;
        }

        &::after {
          bottom: 0;
        }
      }
    }
    .v-input__icon--append {
      .v-icon {
        color: var(--v-secondary-base)!important;
      }
    }
  }
}
</style>
<style scoped>
.dropdown-counter {
  position: sticky;
  bottom: 0px;
  z-index: 1;
  background-color: #fff;
}
</style>
