<script>
import { VRow } from 'vuetify/lib'

let _VRow = VRow

export default {
  name: 'CtRow',
  extends: _VRow
}
</script>
