<template>
  <div>
    <ct-row>
      <ct-col cols="12" :lg="5">
        <ct-card elevation="0" id="compose-editor" class="editor highlight-border">
          <ct-row no-gutters class="compose-tool-bar">
            <ct-col cols="5" class="d-flex flex-column justify-center">
              <span class="t-14 font-weight-bold ml-1">Text Editor</span>
            </ct-col>
            <ct-spacer></ct-spacer>
            <ct-col cols="7" class="text-align-right d-flex align-center justify-end">
              <div>
                <span class="clear-all pa-3 t-14" @click="clearAllText">Clear All</span>
                <ct-button small icon :disabled="!undoEnabled" @click="onUndo"><ct-icon>icon-undo</ct-icon></ct-button>
                <ct-button small icon :disabled="!redoEnabled" style="margin-left:5px;" @click="onRedo"><ct-icon>icon-redo</ct-icon></ct-button>
              </div>
            </ct-col>
          </ct-row>
          <ckeditor
            :editor="editorClass"
            @ready="onComposeEditorReady"
            @focus="onComposeEditorFocus"
            tag-name="textarea"
            placeholder="Type or paste your message here..."
            v-model="editorData.compose"
            :config="editorConfig.compose">
          </ckeditor>
          <ct-row no-gutters class="analyze-tool-bar pa-0">
            <ct-spacer></ct-spacer>
            <ct-col cols="6" class="text-right">
              <ct-button :disabled="!analyzeEnabled" @click="handleAnalyzeClick" class="mr-2">Analyze <ct-icon>mdi-chevron-right</ct-icon></ct-button>
            </ct-col>
          </ct-row>
        </ct-card>
      </ct-col>
      <ct-col cols="12" :lg="7">
        <ct-card elevation="0" id="analyze-editor" class="editor" ref="analyze-editor">
          <div v-if="loading" id="loader-container">
            <ct-loader :show="loading" id="loader"></ct-loader>
            <span class="mt-4">Analysis In Progress...</span>
          </div>
          <div v-else>
            <ct-tabs grow height="64" :value="activeTab" class="analysis-tabs">
              <ct-tab v-for="(emotion, index) of emotions"
                      :key="index"
                      class="emotion-selector flex-grow-0"
                      @click="handleEmotionSelected(`${emotion.name}`)">
                <span class="emotion-name" :class="emotion.name">{{ emotion.name }}</span>
                <span class="emotion-percentage">{{ emotion.percentage}}%</span>
              </ct-tab>
            </ct-tabs>
            <ckeditor
              :editor="editorClass"
              @ready="onAnalyzeEditorReady"
              tag-name="textarea"
              v-model="editorData.analyze"
              :config="editorConfig.analyze">
            </ckeditor>
            <ct-row no-gutters class="copy-tool-bar mx-2">
              <ct-col class="d-flex align-center flex-grow-1" :cols="12" :md="8">
                <span class="t-12 font-weight-semibold hint-text" v-if="emotionDescription"><ct-icon size="20">icon-bulb</ct-icon> {{ emotionDescription }}</span>
              </ct-col>
              <ct-col class="flex-grow-0 text-right" :cols="12" :md="4">
                <v-btn
                  :ripple="false"
                  v-show="showCopy"
                  id="copy-button"
                  outlined
                  color="primary"
                  :disabled="this.message.length <= 0"
                  @click="onCopy">
                  <v-icon left>cticon-clone</v-icon> Copy Text</v-btn>
              </ct-col>
            </ct-row>
          </div>
        </ct-card>
      </ct-col>
    </ct-row>
    <div class="editor-note t-12 text-right">
      <span class="font-weight-medium">Note: </span>
      <span style="color: #63698F;">Emotion impact is analyzed for up to 40 words.</span>
    </div>
    <input type="hidden" id="message-copy" :value="message">
  </div>
</template>

<script>
  import { mapActions, mapState } from 'vuex'
  import { EventBus, Events } from '../../EventBus'
  import { CtRow, CtCol, CtSpacer } from 'clevertap-dashboard-components/components/Grid/GridSystem'
  import { CtTabs, CtTab } from 'clevertap-dashboard-components/components/Tabs'
  import CtIcon from 'clevertap-dashboard-components/components/Icon'
  import CtCard from 'clevertap-dashboard-components/components/Card'
  import CtLoader from 'clevertap-dashboard-components/components/Loader/CtLoader'
  import CtButton from 'clevertap-dashboard-components/components/Button'
  import CKEditor from '@ckeditor/ckeditor5-vue'
  import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor'
  import { modelElementToPlainText } from '@ckeditor/ckeditor5-word-count/src/utils'
  import EssentialsPlugin from '@ckeditor/ckeditor5-essentials/src/essentials'
  import ParagraphPlugin from '@ckeditor/ckeditor5-paragraph/src/paragraph'
  import WordCountPlugin from '@ckeditor/ckeditor5-word-count/src/wordcount'
  import EditorPlugin from './plugins/EditorPlugin'
  import AnalyzeEditorPlugin from './plugins/AnalyzeEditorPlugin'
  import EmotionPlugin from './plugins/emotion/emotion'
  import { ColorLuminance } from './utils'

  const message = 'Type or paste your text here. Click on an emotion to view the words that increase and decrease the strength of that emotion.'

  export default {
    name: 'Editor',
    components: {
      ckeditor: CKEditor.component,
      CtLoader,
      CtCol,
      CtRow,
      CtButton,
      CtSpacer,
      CtTab,
      CtTabs,
      CtCard,
      CtIcon
    },
    beforeDestroy () {
      this.composeEditor = null
      this.analyzeEditor = null
    },
    data () {
      return {
        editorClass: ClassicEditor,
        activeTab: 0,
        composeEditor: null,
        undoEnabled: false,
        redoEnabled: false,
        analyzeEnabled: false,
        analyzeEditor: null,
        selectedEmotion: null,
        wordCount: null,
        characterCount: null,
        maxWords: 40,
        wordsCache: {},
        tokensLoading: false,
        message: message,
        editorData: {
          compose: `<p>${message}</p>`,
          analyze: '<p></p>'
        },
        editorConfig: {
          compose: {
            plugins: [
              EssentialsPlugin,
              ParagraphPlugin,
              EditorPlugin,
              WordCountPlugin
            ],
            toolbar: {
              items: []
            },
            wordCount: {
              onUpdate: (stats) => {
                this.wordCount = stats.words
                this.characterCount = stats.characters
              }
            }
          },
          analyze: {
            plugins: [
              EssentialsPlugin,
              ParagraphPlugin,
              AnalyzeEditorPlugin,
              EmotionPlugin
            ],
            toolbar: {
              items: []
            }
          }
        }
      }
    },
    methods: {
      ...mapActions(['getData', 'recordEvent', 'recordUserProfile']),
      onComposeEditorReady (editor) {
        this.composeEditor = editor
        this.composeEditor.model.document.on('change', (eventInfo, batch) => {
          const undoCommand = this.composeEditor.commands.get('undo')
          this.undoEnabled = undoCommand.isEnabled
          const redoCommand = this.composeEditor.commands.get('redo')
          this.redoEnabled = redoCommand.isEnabled
          this.analyzeEnabled = this.undoEnabled || this.redoEnabled
        })
      },
      onComposeEditorFocus () {
        const message = modelElementToPlainText(this.composeEditor.model.document.getRoot())
            .replace(/[\r\n]+/g, ' ')
        if (message === this.message && this.composeEditor.commands.get('selectAll')) {
          this.composeEditor.execute('selectAll')
        }
      },
      clearAllText () {
        this.composeEditor.setData('<p></p>')
        /*
          Active bug in CK5: https://github.com/ckeditor/ckeditor5/issues/4060#issuecomment-484808750
          Below is a workaround to clear the undo/redo stack on clear.
          Potential memory leak. Update once a fix is released
        */
        this.composeEditor.commands.get('undo')._stack.length = 0
        this.composeEditor.commands.get('redo')._stack.length = 0
        this.undoEnabled = false
        this.redoEnabled = false
      },
      onAnalyzeEditorReady (editor) {
        this.analyzeEditor = editor
        editor.isReadOnly = true
        this.onAnalyze(false)
      },
      clearWordsCache () {
        this.wordsCache = {}
      },
      getWords (emotion) {
        if (this.wordsCache[emotion]) {
          return this.wordsCache[emotion]
        }
        const value = this.words.map((entry, index) => {
          const word = Object.keys(entry)[0]
          const emotions = entry[word]
          let val = null
          let color = '#CBCDD5'
          if (emotions !== null && typeof emotions === 'object') {
            val = emotions[emotion]
            if (val !== 0) {
              const baseColor = val > 0 ? '#009400' : '#E9323C'
              // const multiplier = Math.max(100 - (Math.abs(val) * 5), 0) / 100
              color = ColorLuminance(baseColor, 0)
            }
          }
          if (index >= this.maxWords) {
            color = '#CBCDD5'
            val = -1000
          }
          return { word, impact: val, color: color }
        })
        this.wordsCache[emotion] = value
        return value
      },
      onCopy () {
        const messageCopy = document.querySelector('#message-copy')
        messageCopy.setAttribute('type', 'text')
        messageCopy.select()
        try {
          document.execCommand('copy')
          EventBus.$emit(Events.SHOW_SNACKBAR, { color: 'success', text: 'Text successfully copied' })
        } catch (err) {
          EventBus.$emit(Events.SHOW_SNACKBAR, { color: 'error', text: 'Problem Copying to Clipboard' })
        }
        /* unselect the range */
        messageCopy.setAttribute('type', 'hidden')
        window.getSelection().removeAllRanges()
      },
      onRedo () {
        this.composeEditor.execute('redo')
      },
      onUndo () {
        this.composeEditor.execute('undo')
      },
      handleAnalyzeClick () {
        this.onAnalyze(true)
        if (this.$vuetify.breakpoint.mdAndDown) {
          this.$refs['analyze-editor'].$el.scrollIntoView({ behavior: 'smooth', block: 'start' })
        }
      },
      async onAnalyze (reload) {
        if (this.emotionsLoading) {
          return
        }
        const message = modelElementToPlainText(this.composeEditor.model.document.getRoot())
            .replace(/[\r\n]+/g, ' ')

        if (message.length <= 0 && reload) {
          return
        }
        this.analyzeEnabled = false
        const fetchData = reload && message.length > 0 && message !== this.message
        if (fetchData) {
          this.clearWordsCache()
          this.message = message
          this.tokensLoading = true
          const token = await this.$recaptcha('analysis')
          await this.getData({ sentence: this.message, token })
          this.tokensLoading = false
        }
        if (!this.selectedEmotion && this.emotionNames.length > 0) {
          this.selectedEmotion = this.emotionNames[0]
        }
        if (!this.selectedEmotion) {
          return
        }
        const data = this.getWords(this.selectedEmotion)
        this.activeTab = this.emotionNames.indexOf(this.selectedEmotion)
        this.analyzeEditor.model.change(writer => {
          for (const child of this.analyzeEditor.model.document.getRoot().getChildren()) {
            writer.remove(child)
          }
          const tokens = this.message.match(/[\w'&]+|[^A-Za-z0-9 ]/gu)
          let dataIndex = 0
          tokens.forEach((token, index) => {
            let contentElement
            const entry = data[dataIndex]
            if (entry && entry.word === token) {
              dataIndex += 1
              contentElement = writer.createElement('emotion',
                  {
                    word: entry.word,
                    color: entry.color,
                    index,
                    emotion: this.selectedEmotion,
                    impact: entry.impact
                  })
            } else {
              contentElement = writer.createText(`${token} `)
            }
            this.analyzeEditor.model.insertContent(contentElement)
          })
        })
        // noinspection JSValidateTypes
        this.recordEvent({ 'name': 'Message analysed', 'properties': { timestamp: new Date() } })
      },
      handleEmotionSelected (emotion) {
        this.selectedEmotion = emotion
        this.onAnalyze(false)
      }
    },
    computed: {
      ...mapState(['emotionsLoading', 'emotions', 'words']),
      loading () {
        return this.tokensLoading || this.emotionsLoading
      },
      emotionNames () {
        return (this.emotions || []).map((emotion) => {
          return emotion.name
        })
      },
      showEmotions () {
        return this.haveEmotionsData
      },
      haveEmotionsData () {
        return this.emotions && Object.keys(this.emotions).length > 0
      },
      showCopy () {
        return !this.loading && this.message.length > 0
      },
      emotionDescription () {
        switch (this.selectedEmotion) {
          case 'anticipation':
            return 'Use anticipation for product launches or upcoming sales.'
          case 'trust':
            return 'Add trust to give your customers a feeling of reassurance.'
          case 'fomo':
            return 'Use FOMO sparingly so your limited time offers stand out.'
          case 'joy':
            return 'Joy, of all the emotions, leads to the most social sharing.'
          case 'surprise':
            return 'Surprise your customers by offering unexpected rewards, offers, and discounts.'
          default:
            return ''
        }
      }
    }
  }
</script>

<style>
  .ck.ck-toolbar {
    border: none;
    background-color: transparent;
  }
  .theme--light.v-tabs > .v-tabs-bar {
    background-color: #F9F9FB;
  }
  .v-application .editor.highlight-border {
    border: 2px solid rgba(67, 71, 97, 0.67);
  }
  .v-application .editor.inset-shadow {
    box-shadow: inset 0 1px 3px rgba(0,0,0,0.24) !important;
  }

  .analysis-tabs .v-slide-group__content {
    justify-content: space-between;
  }
</style>

<style scoped lang="scss">
.analysis-tabs {
  border-radius: 4px;

  .v-tab {
    line-height: 24px;
  }
}

.buttons-container {
  justify-content: space-evenly;
  display: flex;
}
.editor {
  position: relative;
  padding: 16px;
  height: 400px;
}

#loader-container {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 12px;
  color: #B8BBC5;
}

#loader {
  width: 32px;
  height: 32px;
}

.compose-tool-bar {
  background-color: #F9F9FB;
  border-radius: 4px;
  height: 44px;
  padding: 0 12px;
}

.clear-all {
  color: #126BFF;
  cursor: pointer;
}

.word-count-container {
  text-align: right;
}

.over-limit {
  color: #FD0031;
}

.text-align-right {
  text-align: right;
}

.word-count {
  font-size: 11px;
}

.words-exceeded-text {
  font-size: 11px;
}

.ct-button .v-icon {
  margin-top: 2px;
}

.analyze-tool-bar {
  padding: 0 10px;
  height: 55px;
  align-items: center;
}

.hint-text {
  height: 100%;
  display: inline-flex;
  align-items: center;
}

.emotion-selector {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  text-transform: capitalize !important;
  text-align: left;
  align-items: flex-start;

  .fomo {
    text-transform: uppercase !important;
  }

  .emotion-name {
    &.anticipation {
      width: 93px;
    }

    &.fomo {
      width: 48px;
    }

    &.trust {
      width: 40px;
    }

    &.surprise {
      width: 65px;
    }

    &.joy {
      width: 28px;
    }
  }
}

.copy-tool-bar {
  margin-top: 10px;
  padding: 0 10px;
  border-radius: 4px;
  background-color: #F0F7FF;
}

#copy-button {
  border: none;
  text-transform: none;
  padding: 0 16px !important;
}

.analyze-tool-bar .theme--light.v-btn.v-btn--disabled .v-icon, .theme--light.v-btn.v-btn--disabled .v-btn__loading {
  color: #fff !important;
}
</style>
<style scoped>
#analyze-editor >>> .v-slide-group__prev {
  display: none;
}
</style>
<style lang="scss">
@import '~vuetify/src/styles/settings/_variables';
@media #{map-get($display-breakpoints, 'sm-and-down')} {
  .editor-container {
    width: calc(100% + 32px);
    margin-left: -16px;
    box-shadow: inset 0 2px 3px 0 rgba(0,0,0,0.24);
  }
  .editor-note {
    margin-right: 16px;
  }
  .copy-tool-bar {
    padding: 10px !important;
    text-align: center;
    .text-right {
      text-align: center !important;
    }
  }
  #compose-editor {
    border: none;
  }
  #analyze-editor .ck-content p{
    line-height: 20px;
  }
  .ck-content p {
    font-size: 20px;
    line-height: 28px;
  }
  .copy-tool-bar {
    height: 82px;
    margin-top: -30px !important;

    .hint-text {
      text-align: left;
    }
  }
}
</style>
