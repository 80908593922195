<script>
import { VSpacer } from 'vuetify/lib'

let _VSpacer = VSpacer

export default {
  name: 'CtSpacer',
  extends: _VSpacer
}
</script>
