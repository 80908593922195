<script>
import { VCol } from 'vuetify/lib'

let _VCol = VCol

export default {
  name: 'CtCol',
  extends: _VCol
}
</script>

<style>

</style>
