const __ACCOUNT_ID = window.__ACCOUNT_ID || null
const __USER_NAME = window.__USER_NAME || null
const __USERNAME = window.__USERNAME || null
const __ACCOUNT_NAME = window.__ACCOUNT_NAME || null
const __USER_AVATAR = window.__USER_AVATAR || null
const __DATE_TIME = window.__DATE_TIME || null
const __IS_TEST_ACCOUNT = window.__IS_TEST_ACCOUNT || false
const __USER_IS_INTERNAL = window.__USER_IS_INTERNAL || false
const __ACCOUNT_INFO = window.ACCOUNT_INFO || {}
const __EFFECTIVE_ROLE = window.__EFFECTIVE_ROLE || 'UNKNOWN_ROLE'
const __IS_GHOST = window.__IS_GHOST || false // need to do a planned depreciation
const __ACCOUNT_IS_INTEGRATED = window.__ACCOUNT_IS_INTEGRATED || window.isDataComing || false
const __ACCOUNT_REGION = window.DATA_CENTER || 'eu'
const __maxDRP = parseInt(window.__maxDRP) || 364
const __SAMPLING_MODE = parseInt(window.SAMPLING_MODE)
const __SEGMENT_ROLE_INFO = window.__SEGMENT_ROLE_INFO
const __HOME_BOARD = window.__HOME_BOARD || -1
const __IS_ACCOUNT_SELF_SERVE = window.__IS_ACCOUNT_SELF_SERVE > -1
const __IS_SELF_SERVE = window.isSelfServe

export const IS_DEMO_ACCOUNT_ID = (__ACCOUNT_ID === 'WWW-WWW-WWWZ' || __ACCOUNT_ID === 'ZWW-WWW-WWWZ')
export const ACCOUNT_ID = __ACCOUNT_ID
export const USER_NAME = __USER_NAME
export const USERNAME = __USERNAME
export const ACCOUNT_NAME = __ACCOUNT_NAME
export const ACCOUNT_REGION = __ACCOUNT_REGION
export const DATE_TIME = __DATE_TIME
export const USER_AVATAR = __USER_AVATAR
export const IS_TEST_ACCOUNT = __IS_TEST_ACCOUNT
export const ACCOUNT_IS_INTEGRATED = __ACCOUNT_IS_INTEGRATED
export const USER_IS_INTERNAL = __USER_IS_INTERNAL
export const ACCOUNT_INFO = __ACCOUNT_INFO
export const EFFECTIVE_ROLE = __EFFECTIVE_ROLE
export const IS_GHOST = __IS_GHOST
export const TIMEZONE_OFFSET_MINS = window.__TIMEZONE_OFFSET_MINUTES || -(new Date()).getTimezoneOffset()
export const UNINSTALL_ENABLED = window.uninstallTrackingEnabled === undefined ? true : window.uninstallTrackingEnabled
export const INVALID_APPLE_CERTIFICATE = window.INVALID_APPLE_CERTIFICATE || false
export const INVALID_APPLE_CERTIFICATE_CAUSE = window.INVALID_APPLE_CERTIFICATE_CAUSE || ''
export const MAX_DRP = __maxDRP
export const SAMPLING_MODE = isNaN(__SAMPLING_MODE) ? 0 : __SAMPLING_MODE
export const SEGMENT_ROLE_INFO = __SEGMENT_ROLE_INFO
export const HOME_BOARD = __HOME_BOARD
export const NEW_CAMPAIGN = window.__NEW_CAMPAIGN || false
export const GO_LICENSE_KEY = '73ff45e2ba1c28c702d95d76423d38f919a57563cb841ca0090412f7e8093f1d269dea2a55d7d9c680f846ee0a7bc18a88c56a7a901e0739e331d5d543e682f0e63226b512084388ac05249091fe2cf6ab6a61f497e571a288288de0fbabc29c5af7f3d457c90d'
export const IS_ACCOUNT_SELF_SERVE = __IS_ACCOUNT_SELF_SERVE // this need to be deprecated
export const IS_SELF_SERVE = __IS_SELF_SERVE

export const SEGMENT_TYPES = {
  LIVE: 1,
  BATCH: 0,
  BOOKMARKED: undefined
}
export const SHOW_NOTIFICATION = window.SHOW_NOTIFICATION === 'true'

export const readCookie = function readCookie (name) {
  var nameEQ = name + '='
  var ca = document.cookie.split(';')
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i]
    while (c.charAt(0) === ' ') {
      c = c.substring(1, c.length)
    }
    if (c.indexOf(nameEQ) === 0) {
      return c.substring(nameEQ.length, c.length)
    }
  }
  return null
}

export const addToUrl = function addToURL (url, keyEqualsVal) {
  var separator = url.indexOf('?') > -1 ? '&' : '?'
  url += (separator + keyEqualsVal)
  return url
}

export const HAS_ANALYTICS_MODE = window.HAS_ANALYTICS_MODE || false
export const analyticsEngagementMode = window.analyticsEngagementMode || (typeof window.localStorage !== 'undefined' && window.localStorage.getItem('analytics_engagement_mode'))
export const NAG_ALERT = window.NAG_ALERT || ''

export const FIELDINDEX = {
  'profile': {
    'browser': 0,
    'device': 1,
    'addressable': 2,
    'gender': 3,
    'age': 4,
    'country': 8,
    'region': 9,
    'city': 10,
    'login_source': 11,
    'os': 12,
    'type': 13,
    'source': 14,
    'idn': 16,
    'email': 17,
    'push': 24,
    'phone': 25,
    'dndpush': 26,
    'dndemail': 27,
    'timezone': 28,
    'dndphone': 31,
    'reachability': 101,
    'geo_ex': 102,
    'dndwhatsapp': 35,
    'wa_tgt': 36,
    'wa_ticket': 37
  },
  'session': {
    'minute': 0,
    'amount': 1,
    'session_time': 2,
    'session_pages': 3,
    'source': 4,
    'Referrer Type': 5,
    'referrer': 6,
    'utm_src': 7,
    'utm_medium': 8,
    'utm_campaign': 9,
    'cookieIDs': 10,
    'utm_flag': 11,
    'first': 13,
    'time_of_the_day': 14,
    'page_url': 15,
    'page_referrer': 16,
    'day_of_the_week': 17,
    'day_of_the_month': 18,
    'last': 19
  },
  'app': {
    'OS Version': 0,
    'App Version': 4,
    'SDK Version': 1,
    'Make': 2,
    'Model': 3
  },
  'dev': {
    'Device Width': 0,
    'Device Height': 1,
    'Device PPI': 2
  },
  'reachability': {
    'All': 0,
    'Android Push': 1,
    'IOS Push': 2,
    'Windows Push': 3,
    'Email': 4,
    'Phone': 5,
    'Chrome Push': 6,
    'Firefox Push': 7,
    'Safari Push': 8,
    'MobilePush': 9,
    'WebPush': 10,
    'WhatsApp': 15
  }
}

export const OPERATOR = {
  'eq': 1,
  'gt': 2,
  'ge': 3,
  'lt': 4,
  'le': 5,
  'bw': 6,
  'co': 7,
  'neq': 8,
  'bwe': 9,
  'set': 10,
  'nset': 11,
  'nco': 12,
  'eqr': 13,
  'neqr': 14,
  'unknown': -1
}

export const SPECIAL_EVENTS = {
  PageEvent: -5,
  ScreenViewed: -6,
  CampaignSent: -7,
  CampaignBounced: -8
}

export const FUNCTIONS = {
  dayOfYear: 1,
  age: 2
}

export const DATATYPE = { // All server data types
  'str': 0,
  'num': 1,
  'enum': 2,
  'date': 3,
  'mixed': -2,
  'unknown': -1
}

export const XDATATYPE = { '0': 'str', '1': 'num', '2': 'enum', '3': 'date', '-2': 'mixed', '-1': 'unknown' }

export const GROUPBYFUNCTIONS = { 'sum': 1, 'avg': 2 }

export const EVENTSTOREFIELDS = {
  'profile': 1,
  'session': 2,
  'eventProps': 3,
  'items': 4,
  'profile_ex': 5,
  'app': 6,
  'dev': 7,
  'geo': 100,
  'geo_ex': 102
}

export const COMPARATOR_PROPERTIES = [
  {
    id: 'eq',
    text: '= equals',
    value: 'eq',
    symbol: '='
  },
  {
    id: 'set',
    text: '∃ exists',
    value: 'set',
    symbol: '∃'
  },
  {
    id: 'nset',
    text: '∄ does not exist',
    value: 'nset',
    symbol: '∄'
  },
  {
    id: 'co',
    text: '∋ contains',
    value: 'co',
    symbol: '∋'
  },
  {
    id: 'nco',
    text: '∌ does not contain',
    value: 'nco',
    symbol: '∌'
  },
  {
    id: 'neq',
    text: '≠ not equals',
    value: 'neq',
    symbol: '≠'
  },
  {
    id: 'bw',
    text: '≏ between',
    value: 'bw',
    symbol: '≏'
  },
  {
    id: 'gt',
    text: '> greater than',
    value: 'gt',
    symbol: '>'
  },
  {
    id: 'lt',
    text: '< less than',
    value: 'lt',
    symbol: '<'
  }
]

export const BINARY_OPTIONS = [
  {
    id: 'yes',
    value: 'yes',
    text: 'Yes'
  }
]

export const EVENT_TIME_OPTIONS = {
  sf: 'First Time',
  sl: 'Last Time',
  count: 'Occurrences'
}

export const EVENT_TIME_COMPARATOR = {
  gt: 'Greater Than',
  lt: 'Less Than',
  bw: 'Between'
}

export const XLATEIDS = { // 0==browser; 1==device; 12==os etc. -- from above FIELDINDEX IDs
  // both PROFILE & SESSION fields are mapped here...since not found a clash so far in what is required
  0: {
    'Others': 0,
    'Firefox': 1,
    'Chrome': 2,
    'Seamonkey': 3,
    'Safari': 4,
    'Opera': 5,
    'IE': 6,
    'UCBrowser': 7,
    'MobileApp': 8
  }, // 0==Browser
  1: { 'Desktop': 0, 'Mobile': 1, 'Tablet': 2, 'TV': 3 }, // 1==Device
  3: { 'Unknown': 0, 'Male': 1, 'Female': 2, 'Others': 3 }, // 3=Gender
  4: { 'Direct': 0, 'Others': 1, 'Search': 2, 'Social': 3, 'Email': 4, 'UnAvailable': 5 }, // 4==Session Source
  12: { 'Others': 0, 'Android': 1, 'iOS': 2, 'Windows': 3, 'Mac': 4, 'BlackBerry': 5, 'Linux': 6 }, // 12==OS
  13: { 'Anonymous': 10, 'New': 0, 'Repeat': 1, 'Addressable': 2, 'Customer': 3 }, // Anonymous is forced as 10 -- server still has 0=New/1=Repeat
  14: { 'Direct': 0, 'Others': 1, 'Search': 2, 'Social': 3, 'Email': 4, 'UnAvailable': 5 } // 14==Profile Source (same values as session source)
}

export const TREND = { 'Daily': 1, 'Weekly': 2, 'Monthly': 3 }

export const XLATENAMESPROFILE = {
  0: {
    0: 'Others',
    1: 'Firefox',
    2: 'Chrome',
    3: 'Seamonkey',
    4: 'Safari',
    5: 'Opera',
    6: 'IE',
    7: 'UCBrowser',
    8: 'MobileApp'
  },
  1: { 0: 'Desktop', 1: 'Mobile', 2: 'Tablet', 3: 'TV' }, // 1==Device
  3: { 0: 'Unknown', 1: 'Male', 2: 'Female' }, // 3==Gender
  5: { 0: 'Unknown', 1: 'Employed', 2: 'Not Employed' }, // 5==work
  4: {
    0: 'Unknown',
    1: 'Below 18 Years',
    2: '18 to 24 years',
    4: '25 to 40 years',
    5: '41 to 60 years',
    6: 'Over 60 years'
  }, // 4==Age
  7: { 0: 'Unknown', 1: 'School', 2: 'College', 3: 'Graduate' },
  12: { 0: 'Others', 1: 'Android', 2: 'iOS', 3: 'Windows', 4: 'Mac', 5: 'BlackBerry', 6: 'Linux' },
  13: { 10: 'Anonymous', 0: 'New', 1: 'Repeat', 2: 'Addressable', 3: 'Customer' }, // Anonymous is forced as 10 -- server still has 0=New/1=Repeat
  14: { 0: 'Direct', 1: 'Others', 2: 'Search', 3: 'Social', 4: 'Email', 5: 'UnAvailable' },
  17: { 0: 'No', 1: 'Yes' },
  24: { 0: 'No', 1: 'Yes' },
  26: { 0: 'No', 1: 'Yes' },
  27: { 0: 'No', 1: 'Yes' },
  25: { 0: 'No', 1: 'Yes' },
  31: { 0: 'No', 1: 'Yes' },
  35: { 0: 'Yes', 1: 'No' },
  101: {
    0: 'All',
    1: 'Android Push',
    2: 'IOS Push',
    3: 'Windows Push',
    4: 'Email',
    5: 'Phone',
    6: 'Chrome Push',
    7: 'Firefox Push',
    8: 'Safari Push',
    9: 'MobilePush',
    10: 'WebPush',
    15: 'WhatsApp'
  }
}

export const RESET_DATA_OPTIONS_ACCOUNT = ['WWW-WWW-WWRZ', 'ZWW-WWW-WWRZ', 'RWW-WWW-WW4Z']
