import client from './client'

const api = {
  get (url) {
    return new Promise((resolve, reject) => {
      client.get(url).then(response => {
        if (response && response.status === 200) {
          resolve(response.data)
        } else {
          resolve({ error: true })
        }
      }).catch(err => {
        resolve({ error: err.toString() })
      })
    })
  },
  post (url, data, headers) {
    return new Promise((resolve, reject) => {
      client.post(url, data, headers).then(response => {
        if (response && response.status === 200) {
          resolve(response.data)
        } else {
          reject(response)
        }
      }).catch(err => {
        resolve({ error: err.toString() })
      })
    })
  },
  put (url, data, headers) {
    return new Promise((resolve, reject) => {
      client.put(url, data, headers).then(response => {
        if (response && response.status === 200) {
          resolve({ success: true })
        } else {
          resolve({ error: true })
        }
      }).catch(err => {
        resolve({ error: err.toString() })
      })
    })
  }
}
export default api
